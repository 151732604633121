.lawSelect {
  @include liReset();
  //margin-top: 20px;
  //margin-bottom: 20px;
  position: relative;
  flex-basis: 520px;
  & > li {
    width: 100%;
    background-color: $inputBorderColor;
    margin-bottom: 4px;
    line-height: 48px;
    padding-left: 17px;
    padding-right: 17px;
    border-radius: 4px;
    transition: background-color 0.1s;
    cursor: pointer;
    @media screen and (max-width: $mobile) {
      line-height: 20px;
      padding: 6px 17px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      background-color: $orange;
    }
  }
}

.lawTltp {
  &Wrapper {
    @media screen and (max-width: $mobile) {
      display: none;
    }
    display: flex;
    flex-basis: 310px;
    position: relative;
  }
  display: flex;
  flex-direction: column;
  background-color: $footerColor;
  //overflow: hidden;
  line-height: 16px;
  //margin-top: 20px;
  //margin-bottom: 20px;
  //width: calc(100% - 310px);
  margin-left: 30px;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 0px 12px 1px rgba(74,53,89,0.27);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //overflow: visible;

  transition: 0.1s;

  opacity: 0;
  z-index: -1;

  &.-active {
    opacity: 1;
    z-index: 10;
  }

  &:before {
    position: absolute;
    content: " ";
    height: 15px;
    width: 15px;
    background-color: $footerColor;
    transform: translate(-50%, 0) rotate(-45deg);
    left: 0;
    //top: 20px;
    //box-shadow: 0px 0px 12px 1px rgba(74,53,89,0.27);
    box-shadow: -3px -3px 6px -1px rgba(74,53,89,0.2);
  }

  &_header {
    display: flex;
    margin-bottom: 10px;
    .svgIcon {
      img {height: 30px !important;}
    }
    h3 {
      margin: 0;
      display: inline;
      text-transform: none;
      line-height: 30px;
      padding-left: 10px;
    }
  }
  &_body {
    font-size: 14px;
    line-height: 20px;
    position: relative;
    flex-grow: 1!important;
    flex-shrink: 1!important;
    div {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      //background-color: red;
      overflow: auto;
    }
  }
  &_footer {
    padding-top: 16px;
    margin-top: auto;
    font-size: 14px;
  }
}

$lawTltpOrder: (1,2,3,4,5,6);
$lawTltpItemCount: 6;

@each $order in $lawTltpOrder {
  .lawTltp.-o#{$order} {
    &:before {
      top: calc((100% / #{$lawTltpItemCount} * #{$order}) - 33px);
    }
  }
}

.lawTltpWrapper {
  &.-requestStep {
    display: none;
    @media screen and (max-width: $mobile) {
      display: block !important;
    }

  }
}

@media screen and (max-width: $mobile) {
.lawTltpWrapper {
  margin-top: 20px;
  border-top: 1px solid #c4bec8;
  &:before {
    content: '';
    position: absolute;
    right: calc(90% - 20px);
    top: -9px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent $white transparent;
    z-index: 10;
  }
  &:after {
    content: '';
    position: absolute;
    left: 10%;
    top: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #c4bec8 transparent;
  }
  .lawTltp {
    display: none;
    &.-active {
      display: flex;
    }
    &:before {
      display: none;
    }
    position: relative;
    bottom: auto;
    margin-left: 0;
    margin-top: 10px;
    padding: 0;
    box-shadow: none;
    background-color: $white;
    &_body div {
      position: relative;
    }
  }
}
}



.services {
  @media screen and (max-width: $mobile) {
    .flex {
      flex-direction: column;
      .boxFlex {
        //margin: auto;
        width: 100%;
        flex-direction: row;
        margin-bottom: 30px;
        @media screen and (max-width: $mobile) {
          margin-bottom: 15px;
        }
        align-items: center;
        span {
          padding-left: 20px;
          text-align: left;
          br {
            content: ' ';
          }
        }
      }
    }
  }
}

.keyBenefits {
  h3 {
    margin-bottom: 6px;
  }
  @media screen and (max-width: $mobile) {
    .flex {
      flex-direction: column;
      .boxFlex {
        margin: auto;
        margin-bottom: 30px;
        @media screen and (max-width: $mobile) {
          margin-bottom: 15px;
        }
      }
    }
  }

}
/*
 * CUSTOM FONT EXAMPLE
 */

/*@font-face {
  font-family: 'DroidSerif';
  src: url(custom/DroidSerif-Regular-webfont.eot);
  src: url(custom/DroidSerif-Regular-webfont.eot?#iefix) format('embedded-opentype'), url(custom/DroidSerif-Regular-webfont.woff2) format('woff2'), url(custom/DroidSerif-Regular-webfont.woff) format('woff'), url(custom/DroidSerif-Regular-webfont.ttf) format('truetype'), url(custom/DroidSerif-Regular-webfont.svg#DroidSerif-Regular-webfont) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DroidSerif';
  src: url(custom/DroidSerif-Italic-webfont.eot);
  src: url(custom/DroidSerif-Italic-webfont.eot?#iefix) format('embedded-opentype'), url(custom/DroidSerif-Italic-webfont.woff2) format('woff2'), url(custom/DroidSerif-Italic-webfont.woff) format('woff'), url(custom/DroidSerif-Italic-webfont.ttf) format('truetype'), url(custom/DroidSerif-Italic-webfont.svg#DroidSerif-Italic-webfont) format('svg');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'DroidSerif';
  src: url(custom/DroidSerif-Bold-webfont.eot);
  src: url(custom/DroidSerif-Bold-webfont.eot?#iefix) format('embedded-opentype'), url(custom/DroidSerif-Bold-webfont.woff2) format('woff2'), url(custom/DroidSerif-Bold-webfont.woff) format('woff'), url(custom/DroidSerif-Bold-webfont.ttf) format('truetype'), url(custom/DroidSerif-Bold-webfont.svg#DroidSerif-Bold-webfont) format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'DroidSerif';
  src: url(custom/DroidSerif-BoldItalic-webfont.eot?#iefix) format('embedded-opentype'), url(custom/DroidSerif-BoldItalic-webfont.woff2) format('woff2'), url(custom/DroidSerif-BoldItalic-webfont.woff) format('woff'), url(custom/DroidSerif-BoldItalic-webfont.ttf) format('truetype'), url(custom/DroidSerif-BoldItalic-webfont.svg#DroidSerif-BoldItalic-webfont) format('svg');
  font-weight: bold;
  font-style: italic;
}
*/

body {
  font-family: 'Roboto', sans-serif;
  color: $basicTextColor;
  font-weight: 400;
  font-size: 16px;
  @media screen and (max-width: $mobile) {
    font-size: 14px;
  }
}

h1 {
  color: $headersColor;
  font-size: 36px;
  font-weight: 300;
  line-height: 44px;
  letter-spacing: 1px;
  @media screen and (max-width: $mobile) {
    font-size: 25px;
    line-height: 31px;
  }
}

h2 {
  color: $headersColor;
  font-size: 36px;
  font-weight: 300;
  line-height: 43px;
  text-align: center;
  letter-spacing: 1px;
  @media screen and (max-width: $mobile) {
    font-size: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 32px;
  }
}

h3 {
  color: $headersColor;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  @media screen and (max-width: $mobile) {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 2px;
  }
}

h4 {
  color: $headersColor;
  font-weight: 300;
  font-size: 24px;
  text-align: center;
  line-height: 27px;
}

h5 {
  color: $headersColor;
  font-size: 16px;
  @media screen and (max-width: $mobile) {
    font-size: 14px;
  }
}

h6 {

}

p {
  color: $basicTextColor;
  font-size: 16px;
  line-height: 22px;
}

a {
  @include linx-simple($headersColor, $orange);
}

.textSmall {
  color: $basicTextColor;
  font-size: 16px;
  line-height: 18px;
}
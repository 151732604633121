@mixin linx-simple ($base, $hover) {
    color: $base;
    transition: all 0.3s ease 0s;
    /*&:visited {
      color: $base;
    }*/
    &:hover {
      color: $hover;
    }
    /*&:active {
      color: $hover;
    }*/
}

@mixin input-placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

@mixin clearFix(){
  &:before,
  &:after{
    content: " ";
    display: table;
  }

  &:after{
    display: block;
    clear: both;
    height: 1px;
    margin-top: -1px;
    visibility: hidden;
  }
  &{
    *zoom: 1;
  }
}

@mixin centerVerticaly() {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin centered-axis-x() {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

@mixin headerPaddingMarginReset() {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

@mixin liReset() {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin pretyScrollBar() {
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

}

@mixin headerMenuHoverState() {
  border-bottom: 2px solid $orange;
  svg path {
    fill: $orange;
  }
}

@mixin alert() {
  width: 16px;
  height: 16px;
  background-color: red;
  position: absolute;
  //left: 30px;
  border-radius: 50%;
  box-shadow: 0 0 0 rgba(204,169,44, 0.4);
  backface-visibility: hidden;
}

@keyframes pulse_animation {
  0% { transform: scale(1); }
  30% { transform: scale(1); }
  40% { transform: scale(1.5); }
  50% { transform: scale(1); }
  60% { transform: scale(1); }
  70% { transform: scale(1.2); }
  80% { transform: scale(1); }
  100% { transform: scale(1); }
}


.hr--text {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  color: $grey;
  & > span {
    position: relative;
    display: inline-block;
  }

  & > span:before, & > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: $grey;
  }

  & > span:before {
    right: 100%;
    margin-right: 15px;
  }
  & > span:after {
    left: 100%;
    margin-left: 15px;
  }
}
//tahle trida slouzi jako override pro case
.payment {

  .caseArrow {
    margin-left: unset !important;
  }
  &Status {
    color: $headersColor;
    margin-left: auto;
    padding-right: 40px;
    @media screen and (max-width: $mobile) {
      padding-right: 20px;
      text-align: right;
    }
  }
}


.pays {
  display: flex;
  @media screen and (max-width: $mobile) {
    flex-direction: column;
    align-items: center;
  }
  .pay {
    flex-basis: 33.33%;
    padding: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-width: 1px 0 1px 1px;
    border-style: solid;
    border-image: linear-gradient(to top, #fff 0%, #fff 5%, $orange 45%, $orange 55%, #fff 95%, #fff 100%) 1 100%;
    position: relative;
    @media screen and (max-width: $mobile) {
      max-width: 400px;
      min-height: 130px;
      width: 100%;
      border-width: 0;
      &:after {
        content: "";
        background: linear-gradient(to right,#fff 0,#fff 1%,#ffa11f 25%,#ffa11f 75%,#fff 99%,#fff 100%);
        display: block;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
      &:last-child:after {
        display: none;
      }
    }
    &:first-child {
      border-style: none;
    }

    &Title {
      margin-top: 0;
      display: block;
      margin-bottom: 20px;
      font-size: 18px;
    }

    &Qr {
      img {
        width: 100%;
      }
    }

    &Content {
      margin-bottom: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 16px;
      &.-small {
        font-size: 12px;
        width: 100%;
        & > div {
          margin-right: auto;
        }
      }
    }
  }

}

.paymentDescription {
  padding-top: 15px;
  padding-left: 20px;
  padding-bottom: 14px;
  position: relative;
  //border-bottom: 1px solid black;
  &:after {
    content:"";
    background: linear-gradient(to right, #fff 0%, #fff 1%, $orange 25%, $orange 75%, #fff 99%, #fff 100%);
    display: block;
    height:1px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  div {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    span {
      font-weight: normal;
    }
  }
}


.paidStatus {
  padding: 20px;
  padding-right: 45px;
  margin-bottom: 2px;
  position: relative;
  &.-success {
    background-color: $lightOrange;
  }
  &.-error {
    background-color: #ff0000;
    color: #ffffff;
  }
  &--close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  &.-closed {
    display: none;
  }
}

.paymentMethod {
  display: block;
  img {
    width: 60px;
    margin-right: 15px;
  }
  > * {
    vertical-align: middle;
  }
  padding-bottom: 10px;
  padding-top: 10px;

  border-bottom: 1px solid $grey;
}

#paymentModal {
  .modalContent {
    text-align: left;
    h1 {
      text-align: center;
    }
  }
}

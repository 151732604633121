.ptContent {

  @mixin listStyleWidth($width) {
    &:before {
      margin-left: -$width;
      width: $width;
    }
  }


  ol {
    counter-reset: item;
    li{
      display: block;
      //padding-left: 10px;
    }
    li:before {
      content: counters(item, ".")". "; counter-increment: item;
      display: inline-block;
    }
  }

  ol {
    margin-bottom: 20px;
    padding-left: 25px;
    > li {
      &:first-child {
        margin-top: 10px;
      }
      margin-bottom: 10px;
      font-weight: bold;
      @include listStyleWidth(25px);
      > ol {
        padding-left: 35px;
        > li {
          font-weight: normal !important;
          @include listStyleWidth(35px);
          > ol {
            padding-left: 45px;
            > li {
              @include listStyleWidth(45px);
            }
          }
        }
      }
    }
  }
  ol.-longer {
    > li {
      > ol {
        padding-left: 50px;
        > li {
          @include listStyleWidth(50px);
          > ol {
            padding-left: 55px;
            > li {
              @include listStyleWidth(55px);
            }
          }
        }
      }
    }
  }

}

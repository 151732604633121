$accordeonBorderColor: #e4e1e7;
$accordeonLeftRightPadding: 20px;

.accordeon {
  position: relative;
  width: 100%;
  //color: #fff;
  overflow: hidden;
  border-bottom: 1px solid $accordeonBorderColor;
  &:first-of-type {
    border-top: 1px solid $accordeonBorderColor;
  }
  &Position {
    color: rgba(0,0,0,0.7);
    font-weight: 400;
  }
}
.accordeon input {
  position: absolute;
  opacity: 0;
  z-index: -1;
  display: block !important;
  width: 0 !important;
  height: 0 !important;
  border: 0 !important;
}
.accordeon label {
  position: relative;
  display: block;
  padding: 18px $accordeonLeftRightPadding;
  background: $white;
  color: $headersColor;
  font-size: 20px;
  line-height: 26px;
  @media screen and (max-width: $mobile) {
    font-size: 18px;
    line-height: 24px;
    padding: 14px 45px 14px 10px;
  }
  font-weight: 400;
  //line-height: 1.2;
  cursor: pointer;
  transition: 0.2s all;
  &:hover {
    background-color: $footerColor;
    .accordeonIcon {
      path {
        fill: $orange;
      }
    }

  }
}


.accordeon-content {
  height: 0;
  overflow: hidden;
  transition: height .35s ease-in-out;
  padding-left: $accordeonLeftRightPadding;
  padding-right: $accordeonLeftRightPadding;
  background-color: $white;

  > *:first-child {
    //padding-top: 30px;
  }

  > *:last-child {
    //padding-bottom: 20px;
  }
}

/* :checked */
.accordeon input:checked ~ .accordeon-content {
  max-height: 5000px;
}
/* Icon */
.accordeonIcon {
  width: 17px;
  position: absolute;
  top: 18px;
  line-height: 60px;
  right: $accordeonLeftRightPadding;
  transition: 0.2s all;
  path {
    transition: 0.2s all;
  }
}
.accordeon input[type=checkbox] + label::after {
  //width: 20px;
}
.accordeon input[type=checkbox]:checked + label {
  //@include accordeonHover();
}
.accordeon input[type=radio] + label::after {
  content: "\25BC";
}
.accordeon input[type=checkbox]:checked + label .accordeonIcon {
  transform: rotate(180deg);
  //color: $darkBlue;
}
.accordeon input[type=radio]:checked + label::after {
  transform: rotateX(180deg);
}

.accordeon label.-open {
  .accordeonIcon {
    transform: rotate(180deg);
  }
}

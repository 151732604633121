.box {
  width: 33%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;

  &Image {
    width: 137px;
    height: 137px;
    background-color: $basicTextColor;
    margin: 0 auto;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    @media screen and (max-width: $mobile) {
      width: 55px;
      height: 55px;
      min-width: 55px;
      min-height: 55px;
    }
    &.-small {
      width: 42px;
      height: 42px;
    }
    &.-medium {
      width: 82px;
      height: 82px;
      min-width: 82px;
      min-height: 82px;
      @media screen and (max-width: $tablet) {
        width: 55px;
        height: 55px;
        min-width: 55px;
        min-height: 55px;
      }
    }
    &.-text {
      display: flex;
      background-color: #d5d0d9;
      color: $white;
      font-size: 36px;
      font-weight: 300;
      justify-content: center;
      align-items: center;
      padding-bottom: 5px;
    }
    &.-empty {
      background-color: transparent !important;
    }
  }

  &Img {
    width: 80px;
    height: 100px;
    margin: 0 auto;
    display: block;
  }

  h4 {
    @include headerPaddingMarginReset();
    padding-top: 7px;
    padding-bottom: 5px;
    @media screen and (max-width: $mobile) {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
  span {
    display: block;
    width: 100%;
    text-align: center;
    @extend .textSmall;
    @media screen and (max-width: $mobile) {
      font-size: 14px;
    }
  }
}

.boxFlex {
  display: flex;
  flex-direction: column;
  width: 33.33%;
  padding: 0 30px;
  @media screen and (max-width: $mobile) {
    padding: 0;
  }
  span {
    display: block;
    width: 100%;
    text-align: center;
    @extend .textSmall;
  }
}

.boxLine {
  border-bottom: 1px solid #eceaee;
  //margin: 10px;
  padding-bottom: 20px;
}
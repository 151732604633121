$selectBigColor: #ffffff;
$selectBigBackground: #ffffff;
$select-width: 220px;
$selectBigHeight: 40px;

$selectBorderRadius: 3px;

$orangeDecoded: '%23FFA11F';
//$inputBorderColorDecoded: '%23d5d0d9';
$inputBorderColorDecoded: '%23B9AEC3';


@mixin selectArrow($color) {
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="79.353px" height="42.831px" viewBox="3.59 20.767 79.353 42.831" enable-background="new 3.59 20.767 79.353 42.831" xml:space="preserve"><path fill="#{$color}" d="M46.059,64.366l-5.481-0.003L3.59,27.382l2.748-6.615l73.872,0.139l2.733,6.618L46.059,64.366z M15.707,28.536L43.32,56.144L70.856,28.64L15.707,28.536z"/></svg>');
}

.select-hidden {
  display: none;
  visibility: hidden;
}
.select.selectBig, .select.selectInline {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  @media screen and (max-width: $smallMobile) {
    font-size: 14px;
  }
  color: $basicTextColor;
  width: $select-width;
  height: $selectBigHeight;

  .select-styled {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $selectBigBackground;
    padding: 8px 25px 8px 15px;
    @media screen and (max-width: $smallMobile) {
      font-size: 14px;
      padding: 10px 25px 8px 15px;
    }
    border-radius: $selectBorderRadius;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.1);
    border: 1px solid $grey;
    transition: 0.2s border-top,0.2s border-bottom,0.2s border-left,0.2s border-right,
    0.2s background-color, 0.2s border-bottom-left-radius, 0.2s border-bottom-right-radius, 0.7s box-shadow;
    //@include inputFocus();
    z-index: 1;


    &:after {
      z-index: 1000;
      display: block;
      content: "";
      //background-image: url('../img/icons/small/highlighted.svg');
      @include selectArrow($inputBorderColorDecoded);
      background-size: 15px auto;
      background-repeat: no-repeat;
      background-position: center center;
      width: 15px;
      height: 38px;
      position: absolute;
      top: 0;
      right: 10px;
      bottom: 0;

      //left: 0;
      transition: transform 0.2s;
      //transform: rotate(180deg);

    }
    &:hover {
      //background-color: darken($selectBigBackground, 2);
    }
    &:active, &.active {
      border-top: 1px solid $orange;
      border-left: 1px solid $orange;
      border-right: 1px solid $orange;
      border-bottom: 1px solid #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;

      //background-color: darken($selectBigBackground, 5);

      &:after {
        @include selectArrow($orangeDecoded);
        //background-image: url('../img/icons/small/highlighted.svg');
        //transform: rotateX(180deg);
      }
    }
  }

}

.select-options {
  display: none;
  position: absolute;
  top: calc(100% - 1px);
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 120px;
  //background-color: darken($selectBigBackground, 5);
  background-color: $selectBigBackground;
  border-bottom-left-radius: $selectBorderRadius;
  border-bottom-right-radius: $selectBorderRadius;



  border-top: none;
  border-left: 1px solid $orange;
  border-right: 1px solid $orange;
  border-bottom: 1px solid $orange;

  overflow-x: hidden;
  overflow-y: auto;

  box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.2);
  li {
    //max-height: 40px;
    margin: 0;
    padding: 8px 8px;
    //text-indent: 15px;
    //border-top: 1px solid darken($selectBigBackground, 10);
    transition: all 0.2s ease-in;
    &:hover {
      background: $orange;
    }
    &[rel="hide"] {
      display: none;
    }
  }
}

.select.selectInline {
  height: 32px !important;
  min-width: 348px;
  @media screen and (max-width: 500px) {
    min-width: initial;
    width: 100%;
  }
  .select-styled {
    padding: 0px 30px 0px 10px !important;
    line-height: 30px;
    font-weight: bold;
    &:after {
      height: 30px;
    }
  }
  .select-options {
    li {
      //max-height: 32px;
      padding: 4px 10px 5px 10px;
      /*&:nth-child(odd) {
        background-color: #f2f2f2;
      }*/
      border-top: 1px solid #f2f2f2;

    }
  }

}


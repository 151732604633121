.designWrapper {
  width: 100%;
  background-color: #f8f8f8;
  padding-left: 50px;
  padding-right: 50px;
  border-top: 1px solid $black;
  border-bottom: 1px solid $black;
}

.designWrapper.-padding {
  padding-top: 20px;
  padding-bottom: 20px;

}
.btnIcon {
  max-height: inherit;
  position: relative;
  height: 20px;
  margin-left: -10px;
  padding-right: 20px;
  //vertical-align: -7%;
  position:absolute;
  top:50%;
  bottom:50%;
  transform:translate(-80%, -50%);
  path {
    transition: all 0.2s;
  }
}

.svgIcon {
 img {
   display: inline;
   height: 16px;
   display: inline-block;
 }
}

$beforeIconWidth: 35px;
h5.-beforeIcon {
  padding-left: $beforeIconWidth;
  position: relative;
  &:before {
    background-repeat: no-repeat;
    background-position: left center;
    width: $beforeIconWidth;
    content:"";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

  }
}

h5.-sponka {
  &:before {
    background-image: url(../img/icons/small/sponka.svg);
    background-size: 20px 20px;
  }
}
h5.-faktura {
  &:before {
    background-image: url(../img/icons/small/faktura.svg);
    background-size: 20px 20px;
  }
}
h5.-komunikace {
  &:before {
    background-image: url(../img/icons/small/komunikace.svg);
    background-size: 20px 20px;
  }
}



h1.-beforeIcon {
  position: relative;
  
  &:before {
    background-repeat: no-repeat;
    background-position: left center;
    width: 40px;
    content:"";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -45px;
    right: 0;
  }
}
h1.-cases {
  &:before {
    background-image: url(../img/icons/small/my_cases.svg);
    background-size: 28px;
  }
}
h1.-profile {
  &:before {
    background-image: url(../img/icons/small/log_in.svg);
    background-size: 28px;
  }
}

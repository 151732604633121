.stripeLegal {
  h2 {
    @include headerPaddingMarginReset();
    padding-top: 30px;
    @media screen and (max-width: $mobile) {
      padding-top: 15px;
    }
  }
  &ST {
    color: $orange;
    text-align: center;
    display: block;
    width: 100%;
    font-size: 13px;
    padding-top: 5px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    @media screen and (max-width: $smallMobile) {
      margin-top: 10px;
    }
  }
  .personSlider {
    margin-top: 20px;
  }
}
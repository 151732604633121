/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active, a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */ }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

.bg-lightPing {
  background-color: #F5F4F7; }

.c-brown {
  color: #66142D; }

html, body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%; }

body {
  box-sizing: border-box; }

* {
  box-sizing: inherit; }

/*a {
  text-decoration: none;
}*/
button {
  cursor: pointer; }

@-moz-document url-prefix() {
  .remove-me {
    border: 1px solid red;
    position: absolute;
    right: 0;
    top: 0; } }

@keyframes pulse_animation {
  0% {
    transform: scale(1); }
  30% {
    transform: scale(1); }
  40% {
    transform: scale(1.5); }
  50% {
    transform: scale(1); }
  60% {
    transform: scale(1); }
  70% {
    transform: scale(1.2); }
  80% {
    transform: scale(1); }
  100% {
    transform: scale(1); } }

/*
 * CUSTOM FONT EXAMPLE
 */
/*@font-face {
  font-family: 'DroidSerif';
  src: url(custom/DroidSerif-Regular-webfont.eot);
  src: url(custom/DroidSerif-Regular-webfont.eot?#iefix) format('embedded-opentype'), url(custom/DroidSerif-Regular-webfont.woff2) format('woff2'), url(custom/DroidSerif-Regular-webfont.woff) format('woff'), url(custom/DroidSerif-Regular-webfont.ttf) format('truetype'), url(custom/DroidSerif-Regular-webfont.svg#DroidSerif-Regular-webfont) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DroidSerif';
  src: url(custom/DroidSerif-Italic-webfont.eot);
  src: url(custom/DroidSerif-Italic-webfont.eot?#iefix) format('embedded-opentype'), url(custom/DroidSerif-Italic-webfont.woff2) format('woff2'), url(custom/DroidSerif-Italic-webfont.woff) format('woff'), url(custom/DroidSerif-Italic-webfont.ttf) format('truetype'), url(custom/DroidSerif-Italic-webfont.svg#DroidSerif-Italic-webfont) format('svg');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'DroidSerif';
  src: url(custom/DroidSerif-Bold-webfont.eot);
  src: url(custom/DroidSerif-Bold-webfont.eot?#iefix) format('embedded-opentype'), url(custom/DroidSerif-Bold-webfont.woff2) format('woff2'), url(custom/DroidSerif-Bold-webfont.woff) format('woff'), url(custom/DroidSerif-Bold-webfont.ttf) format('truetype'), url(custom/DroidSerif-Bold-webfont.svg#DroidSerif-Bold-webfont) format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'DroidSerif';
  src: url(custom/DroidSerif-BoldItalic-webfont.eot?#iefix) format('embedded-opentype'), url(custom/DroidSerif-BoldItalic-webfont.woff2) format('woff2'), url(custom/DroidSerif-BoldItalic-webfont.woff) format('woff'), url(custom/DroidSerif-BoldItalic-webfont.ttf) format('truetype'), url(custom/DroidSerif-BoldItalic-webfont.svg#DroidSerif-BoldItalic-webfont) format('svg');
  font-weight: bold;
  font-style: italic;
}
*/
body {
  font-family: 'Roboto', sans-serif;
  color: #4A3559;
  font-weight: 400;
  font-size: 16px; }
  @media screen and (max-width: 800px) {
    body {
      font-size: 14px; } }

h1 {
  color: #66142D;
  font-size: 36px;
  font-weight: 300;
  line-height: 44px;
  letter-spacing: 1px; }
  @media screen and (max-width: 800px) {
    h1 {
      font-size: 25px;
      line-height: 31px; } }

h2 {
  color: #66142D;
  font-size: 36px;
  font-weight: 300;
  line-height: 43px;
  text-align: center;
  letter-spacing: 1px; }
  @media screen and (max-width: 800px) {
    h2 {
      font-size: 25px;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 32px; } }

h3 {
  color: #66142D;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase; }
  @media screen and (max-width: 800px) {
    h3 {
      font-size: 18px;
      margin-top: 10px;
      margin-bottom: 2px; } }

h4 {
  color: #66142D;
  font-weight: 300;
  font-size: 24px;
  text-align: center;
  line-height: 27px; }

h5 {
  color: #66142D;
  font-size: 16px; }
  @media screen and (max-width: 800px) {
    h5 {
      font-size: 14px; } }

p {
  color: #4A3559;
  font-size: 16px;
  line-height: 22px; }

a {
  color: #66142D;
  transition: all 0.3s ease 0s;
  /*&:visited {
      color: $base;
    }*/
  /*&:active {
      color: $hover;
    }*/ }
  a:hover {
    color: #FFA11F; }

.textSmall, .box span, .boxFlex span {
  color: #4A3559;
  font-size: 16px;
  line-height: 18px; }

body {
  position: relative;
  z-index: 0;
  font-size: 16px;
  line-height: 1.3; }
  @media screen and (max-width: 400px) {
    body {
      font-size: 14px; } }

.content {
  margin: 0 auto; }

.content.-inner {
  width: 100%;
  max-width: 965px; }
  @media screen and (max-width: 1000px) {
    .content.-inner {
      max-width: 750px; } }
  @media screen and (max-width: 800px) {
    .content.-inner {
      padding-left: 20px;
      padding-right: 20px; } }
  @media screen and (max-width: 400px) {
    .content.-inner {
      padding-left: 10px;
      padding-right: 10px; } }

.content.-innerBig {
  width: 100%;
  max-width: 1020px; }

section {
  padding-top: 30px;
  padding-bottom: 25px; }
  @media screen and (max-width: 800px) {
    section {
      padding-top: 10px;
      padding-bottom: 10px; } }

.img__wrap {
  background-color: #ccc;
  position: relative;
  overflow: hidden; }
  .img__wrap img {
    position: relative;
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    max-width: 100%; }

@media screen and (max-width: 800px) {
  .hide-on-mobile {
    display: none !important; } }

@media screen and (min-width: 801px) {
  .hide-over-mobile {
    display: none !important; } }

@media screen and (max-width: 1000px) {
  .hide-on-tablet {
    display: none !important; } }

@media screen and (min-width: 1001px) {
  .hide-over-tablet {
    display: none !important; } }

::-webkit-scrollbar {
  width: 10px; }

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2px; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; }

.placeholder {
  color: #B9AEC3; }

:-moz-placeholder {
  color: #B9AEC3; }

::-moz-placeholder {
  color: #B9AEC3; }

:-ms-input-placeholder {
  color: #B9AEC3; }

::-webkit-input-placeholder {
  color: #B9AEC3; }

/* GENEROVANE ZALEZITOSTI */
.m-t-5 {
  margin-top: 5px !important; }

.p-t-5 {
  padding-top: 5px !important; }

.m-b-5 {
  margin-bottom: 5px !important; }

.p-b-5 {
  padding-bottom: 5px !important; }

.m-l-5 {
  margin-left: 5px !important; }

.p-l-5 {
  padding-left: 5px !important; }

.m-r-5 {
  margin-right: 5px !important; }

.p-r-5 {
  padding-right: 5px !important; }

.m-t-10 {
  margin-top: 10px !important; }

.p-t-10 {
  padding-top: 10px !important; }

.m-b-10 {
  margin-bottom: 10px !important; }

.p-b-10 {
  padding-bottom: 10px !important; }

.m-l-10 {
  margin-left: 10px !important; }

.p-l-10 {
  padding-left: 10px !important; }

.m-r-10 {
  margin-right: 10px !important; }

.p-r-10 {
  padding-right: 10px !important; }

.m-t-15 {
  margin-top: 15px !important; }

.p-t-15 {
  padding-top: 15px !important; }

.m-b-15 {
  margin-bottom: 15px !important; }

.p-b-15 {
  padding-bottom: 15px !important; }

.m-l-15 {
  margin-left: 15px !important; }

.p-l-15 {
  padding-left: 15px !important; }

.m-r-15 {
  margin-right: 15px !important; }

.p-r-15 {
  padding-right: 15px !important; }

.m-t-30 {
  margin-top: 30px !important; }

.p-t-30 {
  padding-top: 30px !important; }

.m-b-30 {
  margin-bottom: 30px !important; }

.p-b-30 {
  padding-bottom: 30px !important; }

.m-l-30 {
  margin-left: 30px !important; }

.p-l-30 {
  padding-left: 30px !important; }

.m-r-30 {
  margin-right: 30px !important; }

.p-r-30 {
  padding-right: 30px !important; }

.flex {
  display: flex; }
  .flexItem {
    display: flex;
    flex-direction: column; }
    .flexItem.-c3 {
      flex-basis: calc(33.33% - 12px); }
    .flexItem.-c2 {
      flex-basis: calc(50% - 10px); }

.flex.-aCenter {
  align-items: center; }

.flex.-space-between {
  justify-content: space-between; }

.spacer {
  flex-grow: 1 !important;
  flex-shrink: 1 !important; }

.-mLeft {
  margin-left: auto; }

.-mAuto {
  margin: auto; }

/* ATOMS - nejmensi prvky */
.select-hidden {
  display: none;
  visibility: hidden; }

.select.selectBig, .select.selectInline {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  color: #4A3559;
  width: 220px;
  height: 40px; }
  @media screen and (max-width: 400px) {
    .select.selectBig, .select.selectInline {
      font-size: 14px; } }
  .select.selectBig .select-styled, .select.selectInline .select-styled {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    padding: 8px 25px 8px 15px;
    border-radius: 3px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid #B9AEC3;
    transition: 0.2s border-top,0.2s border-bottom,0.2s border-left,0.2s border-right, 0.2s background-color, 0.2s border-bottom-left-radius, 0.2s border-bottom-right-radius, 0.7s box-shadow;
    z-index: 1; }
    @media screen and (max-width: 400px) {
      .select.selectBig .select-styled, .select.selectInline .select-styled {
        font-size: 14px;
        padding: 10px 25px 8px 15px; } }
    .select.selectBig .select-styled:after, .select.selectInline .select-styled:after {
      z-index: 1000;
      display: block;
      content: "";
      background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="79.353px" height="42.831px" viewBox="3.59 20.767 79.353 42.831" enable-background="new 3.59 20.767 79.353 42.831" xml:space="preserve"><path fill="%23B9AEC3" d="M46.059,64.366l-5.481-0.003L3.59,27.382l2.748-6.615l73.872,0.139l2.733,6.618L46.059,64.366z M15.707,28.536L43.32,56.144L70.856,28.64L15.707,28.536z"/></svg>');
      background-size: 15px auto;
      background-repeat: no-repeat;
      background-position: center center;
      width: 15px;
      height: 38px;
      position: absolute;
      top: 0;
      right: 10px;
      bottom: 0;
      transition: transform 0.2s; }
    .select.selectBig .select-styled:active, .select.selectBig .select-styled.active, .select.selectInline .select-styled:active, .select.selectInline .select-styled.active {
      border-top: 1px solid #FFA11F;
      border-left: 1px solid #FFA11F;
      border-right: 1px solid #FFA11F;
      border-bottom: 1px solid #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset; }
      .select.selectBig .select-styled:active:after, .select.selectBig .select-styled.active:after, .select.selectInline .select-styled:active:after, .select.selectInline .select-styled.active:after {
        background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="79.353px" height="42.831px" viewBox="3.59 20.767 79.353 42.831" enable-background="new 3.59 20.767 79.353 42.831" xml:space="preserve"><path fill="%23FFA11F" d="M46.059,64.366l-5.481-0.003L3.59,27.382l2.748-6.615l73.872,0.139l2.733,6.618L46.059,64.366z M15.707,28.536L43.32,56.144L70.856,28.64L15.707,28.536z"/></svg>'); }

.select-options {
  display: none;
  position: absolute;
  top: calc(100% - 1px);
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 120px;
  background-color: #ffffff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top: none;
  border-left: 1px solid #FFA11F;
  border-right: 1px solid #FFA11F;
  border-bottom: 1px solid #FFA11F;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2); }
  .select-options li {
    margin: 0;
    padding: 8px 8px;
    transition: all 0.2s ease-in; }
    .select-options li:hover {
      background: #FFA11F; }
    .select-options li[rel="hide"] {
      display: none; }

.select.selectInline {
  height: 32px !important;
  min-width: 348px; }
  @media screen and (max-width: 500px) {
    .select.selectInline {
      min-width: initial;
      width: 100%; } }
  .select.selectInline .select-styled {
    padding: 0px 30px 0px 10px !important;
    line-height: 30px;
    font-weight: bold; }
    .select.selectInline .select-styled:after {
      height: 30px; }
  .select.selectInline .select-options li {
    padding: 4px 10px 5px 10px;
    /*&:nth-child(odd) {
        background-color: #f2f2f2;
      }*/
    border-top: 1px solid #f2f2f2; }

/*
.button {
  display: inline-flex;
  height: 40px;
  max-height: 40px;
  line-height: 35px;
  color: $orange;
  font-size: 18px;
  border: 2px solid $orange;
  border-radius: 20px;
  padding-right: 25px;
  padding-left: 25px;
  text-decoration: none;
  position:relative;
  transition: 0.2s all;
  @media screen and (max-width: $mobile) {
    height: 30px;
    line-height: 25px;
    font-size: 14px;
  }
  svg, img {
    display: inline;
    max-height: 25px;
    align-self: center;
    padding-right: 25px;
    @media screen and (max-width: $mobile) {
      //max-height: 15px;
      padding-right: 15px;
    }
  }
  &.-svg {
    max-height: 40px;
    @media screen and (max-width: $mobile) {
      //max-height: 20px;
    }

  }
  &:hover {
    background-color: $orange;
    color: $white;
    svg path, svg polygon {
      fill: $white;
    }
  }

  &.-icoLogin {
    svg {
      position: relative;
      max-height: 20px;
      @media screen and (max-width: $mobile) {
        max-height: 15px;
      }
    }
  }

}

.button.-icon {
  padding-left: 10px;
}

.button.-small {
  font-size: 16px;
  height: 32px;
  line-height: 28px;
}

.button.-full {
  background: linear-gradient(to right, $gradientRed , $gradientYellow);
  border: none;
  color: $white;
  //transition: 0.2s opacity;
  &:hover {
   //background: linear-gradient(to right, darken($gradientRed,10%) , darken($gradientYellow,10%));
    opacity: 0.9;
  }
}

*/
.button {
  display: inline-block;
  color: #FFA11F;
  font-size: 18px;
  border: 2px solid #FFA11F;
  border-radius: 20px;
  padding-right: 25px;
  padding-left: 25px;
  text-decoration: none;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  line-height: 33px;
  font-weight: normal;
  background-color: transparent;
  cursor: pointer;
  height: 39px;
  max-height: 39px; }
  @media screen and (max-width: 380px) {
    .button {
      font-size: 14px;
      padding: 0 10px 2px 10px;
      line-height: 26.5px;
      height: 31px;
      max-height: 31px; } }
  .button svg path, .button svg polygon {
    transition: all 0.3s ease 0s; }
  .button:hover {
    background-color: #FFA11F;
    color: #ffffff; }
    .button:hover svg path, .button:hover svg polygon {
      fill: #ffffff;
      stroke: #ffffff; }
  .button svg, .button img {
    display: inline-block;
    height: 18px;
    vertical-align: middle;
    margin-right: 20px;
    margin-left: -10px;
    position: relative;
    bottom: 2px; }
    @media screen and (max-width: 380px) {
      .button svg, .button img {
        height: 16px;
        margin-right: 5px;
        margin-left: 0px; } }

.button.-full {
  background-image: linear-gradient(to right, #F8B800 0%, #F8B800 51%, #F51E30 100%);
  background-size: 200% auto;
  border: none;
  color: #ffffff;
  transition: 0.3s all ease-in-out !important;
  background-position: right center;
  line-height: 37px; }
  .button.-full:hover {
    background-position: 40% center; }
  @media screen and (max-width: 380px) {
    .button.-full {
      line-height: 29px; } }

.button.-disabled {
  color: #e5e5e5;
  border: 2px solid #e5e5e5;
  pointer-events: none; }
  .button.-disabled svg path, .button.-disabled svg polygon {
    fill: #e5e5e5; }
  .button.-disabled:hover svg path, .button.-disabled:hover svg polygon {
    fill: #e5e5e5; }

.btnIcon {
  max-height: inherit;
  position: relative;
  height: 20px;
  margin-left: -10px;
  padding-right: 20px;
  position: absolute;
  top: 50%;
  bottom: 50%;
  transform: translate(-80%, -50%); }
  .btnIcon path {
    transition: all 0.2s; }

.svgIcon img {
  display: inline;
  height: 16px;
  display: inline-block; }

h5.-beforeIcon {
  padding-left: 35px;
  position: relative; }
  h5.-beforeIcon:before {
    background-repeat: no-repeat;
    background-position: left center;
    width: 35px;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }

h5.-sponka:before {
  background-image: url(../img/icons/small/sponka.svg);
  background-size: 20px 20px; }

h5.-faktura:before {
  background-image: url(../img/icons/small/faktura.svg);
  background-size: 20px 20px; }

h5.-komunikace:before {
  background-image: url(../img/icons/small/komunikace.svg);
  background-size: 20px 20px; }

h1.-beforeIcon {
  position: relative; }
  h1.-beforeIcon:before {
    background-repeat: no-repeat;
    background-position: left center;
    width: 40px;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -45px;
    right: 0; }

h1.-cases:before {
  background-image: url(../img/icons/small/my_cases.svg);
  background-size: 28px; }

h1.-profile:before {
  background-image: url(../img/icons/small/log_in.svg);
  background-size: 28px; }

.accordeon {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid #e4e1e7; }
  .accordeon:first-of-type {
    border-top: 1px solid #e4e1e7; }
  .accordeonPosition {
    color: rgba(0, 0, 0, 0.7);
    font-weight: 400; }

.accordeon input {
  position: absolute;
  opacity: 0;
  z-index: -1;
  display: block !important;
  width: 0 !important;
  height: 0 !important;
  border: 0 !important; }

.accordeon label {
  position: relative;
  display: block;
  padding: 18px 20px;
  background: #ffffff;
  color: #66142D;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  cursor: pointer;
  transition: 0.2s all; }
  @media screen and (max-width: 800px) {
    .accordeon label {
      font-size: 18px;
      line-height: 24px;
      padding: 14px 45px 14px 10px; } }
  .accordeon label:hover {
    background-color: #f5f4f7; }
    .accordeon label:hover .accordeonIcon path {
      fill: #FFA11F; }

.accordeon-content {
  height: 0;
  overflow: hidden;
  transition: height .35s ease-in-out;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #ffffff; }

/* :checked */
.accordeon input:checked ~ .accordeon-content {
  max-height: 5000px; }

/* Icon */
.accordeonIcon {
  width: 17px;
  position: absolute;
  top: 18px;
  line-height: 60px;
  right: 20px;
  transition: 0.2s all; }
  .accordeonIcon path {
    transition: 0.2s all; }

.accordeon input[type=radio] + label::after {
  content: "\25BC"; }

.accordeon input[type=checkbox]:checked + label .accordeonIcon {
  transform: rotate(180deg); }

.accordeon input[type=radio]:checked + label::after {
  transform: rotateX(180deg); }

.accordeon label.-open .accordeonIcon {
  transform: rotate(180deg); }

input, textarea, select {
  border: 1px solid #B9AEC3;
  color: #4A3559;
  border-radius: 3px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #ffffff;
  transition: 0.2s border, 0.7s box-shadow;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1); }
  input:focus, textarea:focus, select:focus {
    outline: none;
    border: 1px solid #FFA11F; }
  input:focus, textarea:focus, select:focus {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2); }

input.-error, textarea.-error {
  border: 1px solid #f13858; }

input[type="text"], input[type="email"], input[type="password"], select {
  height: 40px; }

textarea {
  padding: 15px; }

select {
  appearance: none;
  color: transparent; }

.inputRow {
  margin-bottom: 4px;
  display: block; }
  .inputRow:before, .inputRow:after {
    content: " ";
    display: table; }
  .inputRow:after {
    display: block;
    clear: both;
    height: 1px;
    margin-top: -1px;
    visibility: hidden; }
  .inputRow {
    *zoom: 1; }
  .inputRow .select {
    display: block; }

.inputRow.-full > * {
  width: 100%; }

input[type=checkbox][disabled] {
  border: 1px solid #B9AEC3 !important; }

input[type=text][disabled] {
  background-color: #eeeeee; }

input[type=checkbox][disabled]:checked::before {
  color: #B9AEC3 !important; }

input[type="radio"], input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  position: relative;
  background-color: #ffffff;
  color: #666;
  top: 10px;
  height: 20px;
  width: 20px;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 7px;
  outline: none;
  border: 1px solid #FFA11F;
  vertical-align: super; }

input[type="radio"]:checked::before {
  display: block;
  width: 10px;
  height: 10px;
  content: '';
  background-color: #FFA11F;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto; }

input[type="checkbox"] {
  border-radius: 0;
  line-height: 20px; }

input[type="checkbox"]:checked::before {
  width: 18px;
  height: 20px;
  display: block;
  content: '\2715';
  color: #FFA11F;
  position: absolute;
  font-size: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center; }

.form-radio:hover {
  background-color: #f7f7f7; }

.form-radio:checked {
  background-color: #f1f1f1; }

label {
  font-size: 16px;
  color: #4A3559;
  line-height: 1.7;
  font-weight: 400;
  cursor: pointer; }

.box {
  width: 33%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px; }
  .boxImage {
    width: 137px;
    height: 137px;
    background-color: #4A3559;
    margin: 0 auto;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }
    @media screen and (max-width: 800px) {
      .boxImage {
        width: 55px;
        height: 55px;
        min-width: 55px;
        min-height: 55px; } }
    .boxImage.-small {
      width: 42px;
      height: 42px; }
    .boxImage.-medium {
      width: 82px;
      height: 82px;
      min-width: 82px;
      min-height: 82px; }
      @media screen and (max-width: 1000px) {
        .boxImage.-medium {
          width: 55px;
          height: 55px;
          min-width: 55px;
          min-height: 55px; } }
    .boxImage.-text {
      display: flex;
      background-color: #d5d0d9;
      color: #ffffff;
      font-size: 36px;
      font-weight: 300;
      justify-content: center;
      align-items: center;
      padding-bottom: 5px; }
    .boxImage.-empty {
      background-color: transparent !important; }
  .boxImg {
    width: 80px;
    height: 100px;
    margin: 0 auto;
    display: block; }
  .box h4 {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-top: 7px;
    padding-bottom: 5px; }
    @media screen and (max-width: 800px) {
      .box h4 {
        padding-top: 2px;
        padding-bottom: 2px; } }
  .box span {
    display: block;
    width: 100%;
    text-align: center; }
    @media screen and (max-width: 800px) {
      .box span {
        font-size: 14px; } }

.boxFlex {
  display: flex;
  flex-direction: column;
  width: 33.33%;
  padding: 0 30px; }
  @media screen and (max-width: 800px) {
    .boxFlex {
      padding: 0; } }
  .boxFlex span {
    display: block;
    width: 100%;
    text-align: center; }

.boxLine {
  border-bottom: 1px solid #eceaee;
  padding-bottom: 20px; }

.status {
  padding-left: 10px; }
  .status:before {
    display: inline-block;
    content: ' ';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 6px;
    position: relative;
    bottom: 1px; }
  .status.-online {
    color: #61AB00; }
    .status.-online:before {
      background-color: #61AB00; }
  .status.-offline {
    color: #F51E30; }
    .status.-offline:before {
      background-color: #F51E30; }

.hr--text {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  color: #B9AEC3; }
  .hr--text > span {
    position: relative;
    display: inline-block; }
  .hr--text > span:before, .hr--text > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: #B9AEC3; }
  .hr--text > span:before {
    right: 100%;
    margin-right: 15px; }
  .hr--text > span:after {
    left: 100%;
    margin-left: 15px; }

.preloader {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0;
  -webkit-transition: opacity 0.4s linear, z-index 0.5s linear;
  -o-transition: opacity 0.4s linear, z-index 0.5s linear;
  transition: opacity 0.4s linear, z-index 0.5s linear;
  z-index: -9; }

.preloader.-active {
  opacity: 0.3;
  z-index: 10000; }

.spinnerWrap {
  position: relative;
  height: 100%;
  width: 100%; }

.spinner {
  margin: 0 auto 20px auto;
  height: 40px;
  width: 40px;
  -webkit-animation: rotate 0.8s infinite linear;
  animation: rotate 0.8s infinite linear;
  border: 4px solid #FFA11F;
  border-right-color: transparent;
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  /* bottom: 0; */
  z-index: 10001; }

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.beforeFooter.-homepage {
  height: 170px; }

.beforeFooter.-other {
  height: 300px;
  display: flex;
  margin-top: 100px; }
  .beforeFooter.-other img {
    height: 200px;
    max-width: 90%;
    margin: auto; }

.onlineStep {
  display: none; }
  .onlineStep.-active {
    display: block; }

.toast__container {
  position: fixed;
  top: 34px;
  right: 34px;
  max-width: calc(100% - 34px);
  z-index: 10000; }

@keyframes appearToast {
  0% {
    opacity: 0;
    top: 50vh; }
  100% {
    opacity: 1;
    top: 0; } }

@keyframes hideToast {
  0% {
    opacity: 1;
    max-height: 30vh;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 16px; }
  50% {
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 16px; }
  100% {
    opacity: 0;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0; } }

.toast {
  position: relative;
  color: white;
  border-radius: 5px;
  padding: 16px;
  animation: appearToast 0.6s;
  margin-bottom: 16px;
  width: 30vw;
  max-width: 100%;
  min-width: 20em;
  max-height: 30vh;
  background-color: #4A3559; }
  .toast *:last-child {
    margin-bottom: 0; }
  .toast.-hide {
    animation: hideToast 0.6s;
    overflow: hidden;
    opacity: 0;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0; }

.dialog {
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 40px;
  max-width: 320px;
  width: 100%;
  display: block;
  z-index: 9999999;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
  padding: 20px; }
  .dialog h5 {
    margin: 0 0 20px 0; }
  .dialogButton:first-of-type {
    margin-right: 10px; }

.modifiedAlert {
  width: 14px;
  height: 14px;
  position: absolute;
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  backface-visibility: hidden;
  display: inline-block;
  bottom: 3px; }

.caseLabel .modifiedAlert {
  position: relative;
  margin-right: 10px;
  min-width: 10px;
  display: inline-block;
  vertical-align: top;
  margin-bottom: auto;
  margin-top: 9px; }

.banners {
  width: 100%; }

.trivi {
  color: #ffffff;
  height: 90px;
  background-color: #08b0cd;
  overflow: hidden; }
  @media screen and (max-width: 860px) {
    .trivi {
      height: auto;
      padding-top: 5px;
      padding-bottom: 10px; } }
  .trivi p {
    color: #ffffff;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400; }
    @media screen and (max-width: 860px) {
      .trivi p {
        font-size: 14px; }
        .trivi p br {
          display: none; } }
  .trivi .content {
    display: flex; }
    @media screen and (max-width: 1000px) {
      .trivi .content {
        max-width: calc(100% - 40px) !important; } }
    @media screen and (max-width: 860px) {
      .trivi .content {
        flex-direction: column; } }
    @media screen and (max-width: 800px) {
      .trivi .content {
        padding-left: 0;
        padding-right: 0; } }
  .triviOffer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 90px; }
    @media screen and (max-width: 860px) {
      .triviOffer {
        margin: auto;
        height: auto; } }
    .triviOffer span {
      display: flex;
      align-items: center;
      width: 58px;
      height: 58px;
      min-width: 58px;
      justify-content: center;
      border: 3px solid #ffffff;
      border-radius: 50%;
      font-size: 26px;
      margin-right: auto; }
    .triviOffer p {
      margin-left: 20px; }
      @media screen and (max-width: 860px) {
        .triviOffer p {
          margin-left: 10px; } }
  .triviDiscount {
    margin-left: auto;
    height: 90px;
    display: flex;
    align-items: center; }
    @media screen and (max-width: 860px) {
      .triviDiscount {
        margin: auto;
        height: auto; } }
    .triviDiscount p {
      text-align: right;
      margin-right: 10px;
      font-weight: bold; }
      @media screen and (max-width: 860px) {
        .triviDiscount p {
          text-align: left; } }
  .triviCircle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffe01b;
    flex-basis: 120px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    top: 58%;
    transform: translateY(-50%);
    flex-direction: column; }
    @media screen and (max-width: 860px) {
      .triviCircle {
        width: 70px;
        min-width: 70px;
        flex-basis: 70px;
        height: 70px;
        min-height: 70px;
        transform: translateY(0);
        top: 0; } }
    .triviCircle span {
      color: #000000; }
      .triviCircle span:nth-child(1) {
        font-weight: bold;
        font-size: 30px;
        padding-top: 10px;
        font-weight: bold; }
        @media screen and (max-width: 860px) {
          .triviCircle span:nth-child(1) {
            padding-top: 0;
            font-size: 22px; } }
        @media screen and (max-width: 400px) {
          .triviCircle span:nth-child(1) {
            font-size: 20px;
            letter-spacing: -1px; } }
      .triviCircle span:nth-child(2) {
        text-transform: uppercase;
        font-size: 14px; }
        @media screen and (max-width: 860px) {
          .triviCircle span:nth-child(2) {
            padding-top: 0;
            font-size: 12px; } }

/* Komponenty - slozitejsi prvky */
.topMenu {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-image: url(../img/zahlavi.jpg);
  background-repeat: no-repeat;
  background-size: 100% 28px;
  height: 28px; }
  @media screen and (max-width: 800px) {
    .topMenu {
      display: none; } }
  .topMenu > *:not(.spacer) {
    display: inline-block;
    line-height: 27.5px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
    font-size: 13px;
    text-decoration: none;
    min-width: 150px;
    font-weight: bold;
    height: 27px;
    padding-left: 20px;
    padding-right: 20px; }
    .topMenu > *:not(.spacer):hover {
      color: #66142D; }
    .topMenu > *:not(.spacer).-active {
      background: linear-gradient(to right, #ffbc46, #ff9544); }
  .topMenuLang {
    text-align: right;
    color: #4A3559 !important;
    font-weight: normal !important;
    min-width: auto; }

.menu {
  display: flex;
  align-content: center;
  padding-left: 20px;
  padding-right: 20px;
  height: 60px;
  position: relative; }
  @media screen and (max-width: 800px) {
    .menu {
      border-bottom: 1px solid #FFA11F; } }
  .menu > * {
    align-self: flex-end; }
    @media screen and (max-width: 800px) {
      .menu > * {
        margin: auto; } }
  .menuLogo {
    align-self: center;
    display: inline-block; }
    .menuLogo img {
      display: block;
      height: 40px;
      width: auto; }
  .menuBurger {
    height: 24px;
    display: none;
    cursor: pointer; }
    @media screen and (max-width: 800px) {
      .menuBurger {
        display: block; } }

@keyframes disop {
  0% {
    opacity: 0;
    visibility: visible; }
  10% {
    opacity: 0;
    visibility: visible; }
  100% {
    opacity: 1;
    visibility: visible; } }

nav.navMenu {
  display: block;
  z-index: 40; }
  nav.navMenu > ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    nav.navMenu > ul > li {
      display: block;
      float: left;
      padding-left: 60px;
      position: relative;
      border-bottom: 1px solid #FFA11F;
      font-size: 18px; }
      nav.navMenu > ul > li .modifiedAlert {
        left: 42px;
        top: 4px; }
        @media screen and (max-width: 1000px) {
          nav.navMenu > ul > li .modifiedAlert {
            left: 13px; } }
        @media screen and (max-width: 800px) {
          nav.navMenu > ul > li .modifiedAlert {
            left: 2px;
            top: 12px; } }
      @media screen and (max-width: 1000px) {
        nav.navMenu > ul > li {
          padding-left: 30px; } }
      nav.navMenu > ul > li:first-child {
        padding-left: 0; }
      nav.navMenu > ul > li > a {
        display: block;
        text-decoration: none;
        padding-bottom: 5px;
        height: 28px;
        border-bottom: 2px solid #ffffff;
        z-index: 10; }
        nav.navMenu > ul > li > a.withArrow {
          pointer-events: none; }
          nav.navMenu > ul > li > a.withArrow svg {
            margin-left: 10px;
            width: 17px; }
            nav.navMenu > ul > li > a.withArrow svg path {
              transition: fill 0.2s; }
        nav.navMenu > ul > li > a:hover, nav.navMenu > ul > li > a.-active {
          border-bottom: 2px solid #FFA11F; }
          nav.navMenu > ul > li > a:hover svg path, nav.navMenu > ul > li > a.-active svg path {
            fill: #FFA11F; }
      nav.navMenu > ul > li:hover > ul {
        animation: disop 0.2s forwards;
        animation-delay: 0.2s; }
      nav.navMenu > ul > li > ul {
        opacity: 0;
        visibility: hidden;
        display: block;
        position: absolute;
        top: 100%;
        right: 0;
        background: linear-gradient(to bottom, transparent 3px, #ffffff 3px);
        list-style: none;
        margin: 0;
        padding: 0 12px;
        padding-bottom: 8px;
        box-shadow: 0px 5px 5px 0 rgba(0, 0, 0, 0.2);
        min-width: 250px;
        border-radius: 10px; }
        nav.navMenu > ul > li > ul.-extended {
          padding: 0 12px 8px 30px !important; }
        nav.navMenu > ul > li > ul li.visibilityStripe {
          background-color: transparent; }
        nav.navMenu > ul > li > ul > li {
          border-bottom: 1px solid #dcd8d0;
          font-size: 17px;
          transition: border-bottom 0.2s;
          position: relative; }
          nav.navMenu > ul > li > ul > li .modifiedAlert {
            left: -18px;
            top: 12px; }
            @media screen and (max-width: 800px) {
              nav.navMenu > ul > li > ul > li .modifiedAlert {
                left: 0;
                top: 15px; } }
          nav.navMenu > ul > li > ul > li:hover {
            border-bottom: 1px solid #FFA11F; }
          nav.navMenu > ul > li > ul > li:first-child {
            padding-top: 5px; }
          nav.navMenu > ul > li > ul > li a {
            display: block;
            padding-bottom: 2px;
            text-decoration: none;
            padding-top: 8px;
            transition: padding-left 0.2s; }
            nav.navMenu > ul > li > ul > li a:hover {
              padding-left: 20px; }
  @media screen and (max-width: 800px) {
    nav.navMenu.-header {
      display: none;
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      background-color: #ffffff;
      padding: 20px 10px 20px 10px; }
      nav.navMenu.-header ul > li {
        display: block;
        width: 100%;
        padding-left: 20px;
        padding-top: 10px;
        padding-bottom: 4px; }
        nav.navMenu.-header ul > li:first-child {
          padding-left: 20px; }
        nav.navMenu.-header ul > li:last-child {
          border-bottom: none; }
        nav.navMenu.-header ul > li a {
          border-bottom: 0;
          font-size: 16px; }
          nav.navMenu.-header ul > li a:hover {
            border-bottom: 0; }
        nav.navMenu.-header ul > li:hover > ul {
          animation: none; }
        nav.navMenu.-header ul > li > ul {
          opacity: 1;
          visibility: visible;
          padding: 0;
          border-radius: 0;
          box-shadow: none;
          position: relative;
          padding-bottom: 10px; }
          nav.navMenu.-header ul > li > ul li {
            padding-bottom: 5px;
            padding-top: 5px; }
            nav.navMenu.-header ul > li > ul li:first-child {
              padding-top: 0px; } }
  nav.navMenu.-header.-open {
    display: block !important; }

footer, .footer {
  background-color: #F5F4F7;
  padding-top: 30px;
  padding-bottom: 40px; }
  footer nav, .footer nav {
    display: block;
    width: 300px;
    margin-right: 20px; }
    footer nav ul, .footer nav ul {
      list-style: none;
      margin: 0;
      padding: 0; }
      footer nav ul li a, .footer nav ul li a {
        display: block;
        font-size: 13px;
        text-decoration: none;
        padding: 8px 0;
        border-bottom: 1px solid #e4e1e7; }
        footer nav ul li a:hover, .footer nav ul li a:hover {
          border-bottom: 1px solid #FFA11F; }
  footerLogo, .footerLogo {
    display: block;
    margin-left: auto;
    margin-bottom: auto;
    justify-self: flex-start;
    width: 200px; }
    footerLogo.-en, .footerLogo.-en {
      width: 190px; }
  footerStripe, .footerStripe {
    background-image: linear-gradient(to right, #392648, #424242);
    width: 100%;
    height: 64px; }

@media screen and (max-width: 800px) {
  footer .content, .footer .content {
    flex-direction: column;
    justify-content: center; }
    footer .content nav, .footer .content nav {
      margin: auto;
      text-align: center; }
    footer .content .footerLogo, .footer .content .footerLogo {
      margin: 20px auto 0 auto; } }

.personSlider {
  height: 280px;
  position: relative;
  width: 100%;
  display: block; }
  @media screen and (max-width: 800px) {
    .personSlider {
      height: 200px; } }
  .personSliderCircles {
    text-align: center;
    padding-bottom: 20px; }
    .personSliderCircles .circle {
      display: inline-block;
      width: 9px;
      height: 9px;
      background-color: #d5d0d9;
      border-radius: 50%;
      margin-left: 15px;
      margin-right: 15px;
      cursor: pointer; }
      .personSliderCircles .circle.-full {
        background-color: #FFA11F; }

.personSlide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  transition: 1s opacity;
  flex-direction: row;
  justify-content: center; }
  @media screen and (max-width: 800px) {
    .personSlide .box {
      padding-left: 10px;
      padding-right: 10px; }
      .personSlide .box h4 {
        font-size: 20px;
        line-height: 24px; } }
  @media screen and (max-width: 550px) {
    .personSlide {
      flex-direction: column;
      display: flex;
      justify-content: center; }
      .personSlide .box {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center; }
        .personSlide .box span {
          display: none; }
        .personSlide .box div {
          margin-top: auto;
          margin-bottom: auto; }
        .personSlide .box h4 {
          font-size: 20px; }
        .personSlide .boxImage {
          margin: 0 20px; } }
  @media screen and (max-width: 450px) {
    .personSlide .box {
      padding-left: 0;
      justify-content: left; }
      .personSlide .boxImage {
        margin-left: 0; } }
  .personSlide.-visible {
    z-index: 10 !important; }

.lawSelect {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  flex-basis: 520px; }
  .lawSelect > li {
    width: 100%;
    background-color: #d5d0d9;
    margin-bottom: 4px;
    line-height: 48px;
    padding-left: 17px;
    padding-right: 17px;
    border-radius: 4px;
    transition: background-color 0.1s;
    cursor: pointer; }
    @media screen and (max-width: 800px) {
      .lawSelect > li {
        line-height: 20px;
        padding: 6px 17px; } }
    .lawSelect > li:last-child {
      margin-bottom: 0; }
    .lawSelect > li:hover {
      background-color: #FFA11F; }

.lawTltp {
  display: flex;
  flex-direction: column;
  background-color: #f5f4f7;
  line-height: 16px;
  margin-left: 30px;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 0px 12px 1px rgba(74, 53, 89, 0.27);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.1s;
  opacity: 0;
  z-index: -1; }
  .lawTltpWrapper {
    display: flex;
    flex-basis: 310px;
    position: relative; }
    @media screen and (max-width: 800px) {
      .lawTltpWrapper {
        display: none; } }
  .lawTltp.-active {
    opacity: 1;
    z-index: 10; }
  .lawTltp:before {
    position: absolute;
    content: " ";
    height: 15px;
    width: 15px;
    background-color: #f5f4f7;
    transform: translate(-50%, 0) rotate(-45deg);
    left: 0;
    box-shadow: -3px -3px 6px -1px rgba(74, 53, 89, 0.2); }
  .lawTltp_header {
    display: flex;
    margin-bottom: 10px; }
    .lawTltp_header .svgIcon img {
      height: 30px !important; }
    .lawTltp_header h3 {
      margin: 0;
      display: inline;
      text-transform: none;
      line-height: 30px;
      padding-left: 10px; }
  .lawTltp_body {
    font-size: 14px;
    line-height: 20px;
    position: relative;
    flex-grow: 1 !important;
    flex-shrink: 1 !important; }
    .lawTltp_body div {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: auto; }
  .lawTltp_footer {
    padding-top: 16px;
    margin-top: auto;
    font-size: 14px; }

.lawTltp.-o1:before {
  top: calc((100% / 6 * 1) - 33px); }

.lawTltp.-o2:before {
  top: calc((100% / 6 * 2) - 33px); }

.lawTltp.-o3:before {
  top: calc((100% / 6 * 3) - 33px); }

.lawTltp.-o4:before {
  top: calc((100% / 6 * 4) - 33px); }

.lawTltp.-o5:before {
  top: calc((100% / 6 * 5) - 33px); }

.lawTltp.-o6:before {
  top: calc((100% / 6 * 6) - 33px); }

.lawTltpWrapper.-requestStep {
  display: none; }
  @media screen and (max-width: 800px) {
    .lawTltpWrapper.-requestStep {
      display: block !important; } }

@media screen and (max-width: 800px) {
  .lawTltpWrapper {
    margin-top: 20px;
    border-top: 1px solid #c4bec8; }
    .lawTltpWrapper:before {
      content: '';
      position: absolute;
      right: calc(90% - 20px);
      top: -9px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #ffffff transparent;
      z-index: 10; }
    .lawTltpWrapper:after {
      content: '';
      position: absolute;
      left: 10%;
      top: -10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #c4bec8 transparent; }
    .lawTltpWrapper .lawTltp {
      display: none;
      position: relative;
      bottom: auto;
      margin-left: 0;
      margin-top: 10px;
      padding: 0;
      box-shadow: none;
      background-color: #ffffff; }
      .lawTltpWrapper .lawTltp.-active {
        display: flex; }
      .lawTltpWrapper .lawTltp:before {
        display: none; }
      .lawTltpWrapper .lawTltp_body div {
        position: relative; } }

.modal {
  position: absolute;
  z-index: 999999;
  top: -105%;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 635px;
  display: block;
  background-color: #ffffff;
  border-radius: 20px;
  transition: top 0.3s;
  transition-delay: 0.2s;
  /* posuny prvku */ }
  @media screen and (max-width: 800px) {
    .modal {
      border-radius: 0; } }
  .modal h5, .modal p {
    margin: 0 0 10px 0; }
  .modalContent {
    display: flex;
    flex-direction: column;
    max-width: 320px;
    margin: 0 auto;
    text-align: center; }
    .modalContentWrapper {
      position: relative;
      width: 100%;
      padding: 30px 150px 50px 150px; }
      @media screen and (max-width: 800px) {
        .modalContentWrapper {
          padding: 20px 20px 40px 20px; } }
  .modal h1 {
    margin-top: 0; }
  .modalSocial {
    margin-top: 20px;
    margin-bottom: 15px; }
    .modalSocialFacebook {
      height: 39px;
      width: 39px; }
      @media screen and (max-width: 380px) {
        .modalSocialFacebook {
          width: 31px; } }
  .modal .navMenu {
    margin: 10px auto 15px auto; }
  .modal .inputRow:last-of-type {
    margin-bottom: 15px; }
  .modalConditions {
    text-align: left;
    padding-left: 15px; }
  .modalSend {
    margin-top: 20px; }
  .modal .-login:not([data-modal-switch]) {
    display: none; }
  .modal .-register:not([data-modal-switch]) {
    display: none; }
  .modal .-password:not([data-modal-switch]) {
    display: none; }
  .modal .-passwordChange:not([data-modal-switch]) {
    display: none; }
  .modal--close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer; }
  .modal .-muiltiuser {
    text-align: left;
    width: 100%; }

/* nastaveni active stavu pro login a pro register */
.modal.-login .-login {
  display: block; }

.modal.-login a.-login {
  display: inline-flex; }

.modal.-login .navMenu li a.-login {
  border-bottom: 2px solid #FFA11F; }
  .modal.-login .navMenu li a.-login svg path {
    fill: #FFA11F; }

.modal.-register .-register {
  display: block; }

.modal.-register a.-register {
  display: inline-flex; }

.modal.-register .navMenu li a.-register {
  border-bottom: 2px solid #FFA11F; }
  .modal.-register .navMenu li a.-register svg path {
    fill: #FFA11F; }

.modal.-password .-password {
  display: block; }

.modal.-password a.-password {
  display: inline-flex; }

.modal.-password .navMenu li a.-password {
  border-bottom: 2px solid #FFA11F; }
  .modal.-password .navMenu li a.-password svg path {
    fill: #FFA11F; }

.modal.-passwordChange .-passwordChange {
  display: block; }

.modal.-passwordChange a.-passwordChange {
  display: inline-flex; }

.modal.-passwordChange .navMenu li a.-passwordChange {
  border-bottom: 2px solid #FFA11F; }
  .modal.-passwordChange .navMenu li a.-passwordChange svg path {
    fill: #FFA11F; }

.modalOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -10;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 0;
  transition: opacity 0.2s ease-in, z-index 0.2s; }
  .modalOverlayLayer {
    z-index: 1200;
    background: #4A3559;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.88; }

.modalOverlay.-open {
  z-index: 1100;
  opacity: 1; }
  .modalOverlay.-open .modal {
    top: 40px; }
    @media screen and (max-width: 600px) {
      .modalOverlay.-open .modal {
        top: 0; } }

p.input.-error {
  margin: 5px 0;
  color: #f13858; }

.caseTitle {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 35px; }
  .caseTitle > * {
    align-self: center; }

.case {
  display: block;
  width: 850px;
  margin: 0 auto;
  transition: width 0.2s;
  margin-top: 10px;
  transition: all 0.5s;
  /* LEVA CAST dokumenty, a faktury */
  /* PRAVA CAST komunikace a chat */ }
  @media screen and (max-width: 870px) {
    .case {
      width: 100%; } }
  .caseLabel {
    position: relative;
    border-top: 1px solid #d5d0d9;
    border-bottom: 1px solid #d5d0d9;
    background-color: #f5f4f7;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer; }
    .caseLabel.-noclick {
      cursor: auto !important; }
  .caseName {
    display: flex;
    font-size: 20px;
    color: #66142D;
    align-items: center;
    padding-top: 17px;
    padding-bottom: 5px; }
    .caseName img.caseArrow {
      height: 10px;
      position: relative;
      bottom: -2px;
      transition: transform 0.2s;
      margin-left: auto; }
    .caseNameFirst {
      align-self: flex-start; }
  .caseData {
    padding-bottom: 15px;
    font-size: 14px; }
    .caseDataDownload {
      float: right;
      padding-right: 60px;
      padding-left: 20px; }
      @media screen and (max-width: 800px) {
        .caseDataDownload {
          padding-right: 40px; } }
      .caseDataDownload img {
        height: 20px;
        display: inline-block;
        cursor: pointer; }
  .caseContent {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    padding-bottom: 40px;
    transition: all 0.5s;
    min-height: 200px; }
    @media screen and (max-width: 900px) {
      .caseContent {
        flex-direction: column;
        padding-bottom: 20px; } }
    .caseContentWrapper {
      max-height: 0;
      overflow: hidden;
      transition: all 0.3s;
      position: relative; }
  .case.-open {
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    margin-bottom: 10px; }
    .case.-open .caseLabel {
      background-color: #ffc49f; }
    .case.-open .caseContentWrapper {
      max-height: 1000px; }
    .case.-open .caseArrow {
      transform: rotate(180deg); }
  .caseLeft {
    flex-basis: 590px; }
    @media screen and (max-width: 1000px) {
      .caseLeft {
        flex-basis: 500px; } }
    @media screen and (max-width: 900px) {
      .caseLeft {
        flex-basis: auto; } }
  .caseDoku {
    display: flex;
    min-height: 63px;
    align-content: center;
    justify-content: center; }
    .caseDoku a {
      margin-left: auto;
      margin-bottom: auto;
      margin-top: auto; }
      @media screen and (max-width: 900px) {
        .caseDoku a {
          font-size: 0;
          padding-left: 15px;
          padding-right: 15px; }
          .caseDoku a svg {
            margin-left: 0 !important;
            margin-right: 0 !important; } }
    .caseDokuDate {
      min-width: 85px; }
  .caseTable {
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    margin: 0px;
    display: block;
    min-height: 150px; }
    @media screen and (max-width: 900px) {
      .caseTable {
        min-height: auto; } }
    .caseTable table {
      border-collapse: separate;
      border-spacing: 7px 0;
      width: 100%; }
      .caseTable table tr {
        cursor: pointer; }
      .caseTable table tr:first-child td {
        border-top: 1px solid #d5d0d8; }
      .caseTable table td {
        border-bottom: 1px solid #d5d0d8;
        font-size: 14px;
        vertical-align: top;
        padding-top: 5px;
        padding-bottom: 5px;
        vertical-align: middle;
        word-break: break-word; }
        .caseTable table td:first-child {
          padding-left: 35px; }
        .caseTable table td img {
          display: block;
          width: 20px;
          vertical-align: middle;
          margin-left: -35px;
          float: left; }
      @media screen and (max-width: 520px) {
        .caseTable table td.-hideOnMobile {
          display: none; } }
  .caseRight {
    width: 590px;
    margin-left: 20px;
    flex: 1; }
    @media screen and (max-width: 900px) {
      .caseRight {
        width: 100%;
        margin-left: 0; } }
  .caseKomunikace {
    display: flex;
    min-height: 63px;
    align-content: center;
    justify-content: center; }
    .caseKomunikace a {
      margin-left: auto;
      margin-bottom: auto;
      margin-top: auto; }
  .case .caseChat {
    max-height: 250px;
    overflow-x: hidden; }
    @media screen and (max-width: 500px) {
      .case .caseChat {
        display: none; } }
  .caseFile {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    z-index: 20;
    transition: 0.2s opacity;
    text-align: center; }
    .caseFile.-open {
      opacity: 1;
      visibility: visible; }
    .caseFileContent {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center; }
    .caseFile--close {
      position: absolute;
      width: 40px;
      cursor: pointer;
      right: 10px;
      top: 10px;
      padding: 10px; }
    .caseFile--upload {
      display: none;
      margin-top: 10px; }
      .caseFile--upload.-ready {
        display: inline-block; }
    .caseFileLabel {
      width: 100%;
      height: calc(100% - 40px);
      height: 100%;
      border: 2px dotted #B9AEC3;
      background-color: #f9f9f9; }
      .caseFileLabel.-onDragged {
        background-color: #f1f1f1; }
      .caseFileLabel span {
        display: inline-block;
        padding-top: 30px; }
      .caseFileLabel * {
        pointer-events: none; }
  .caseFaktuHeader {
    display: flex;
    flex-direction: row; }
    .caseFaktuHeader a {
      margin-left: auto;
      margin-top: auto;
      margin-bottom: auto; }

.caseNoCase {
  max-width: 600px;
  display: block;
  width: 100%;
  padding: 0 20px; }

.moreCasesWrap {
  display: flex;
  justify-content: center;
  margin: 40px 0 35px 0; }

.moreCases {
  height: 0;
  overflow: hidden; }

@media screen and (max-width: 800px) {
  .services .flex {
    flex-direction: column; }
    .services .flex .boxFlex {
      width: 100%;
      flex-direction: row;
      margin-bottom: 30px;
      align-items: center; } }
    @media screen and (max-width: 800px) and (max-width: 800px) {
      .services .flex .boxFlex {
        margin-bottom: 15px; } }

@media screen and (max-width: 800px) {
      .services .flex .boxFlex span {
        padding-left: 20px;
        text-align: left; }
        .services .flex .boxFlex span br {
          content: ' '; } }

.keyBenefits h3 {
  margin-bottom: 6px; }

@media screen and (max-width: 800px) {
  .keyBenefits .flex {
    flex-direction: column; }
    .keyBenefits .flex .boxFlex {
      margin: auto;
      margin-bottom: 30px; } }
    @media screen and (max-width: 800px) and (max-width: 800px) {
      .keyBenefits .flex .boxFlex {
        margin-bottom: 15px; } }

.profile {
  max-width: 400px;
  margin: 0 auto; }
  .profile h5 {
    margin-bottom: 0;
    text-transform: uppercase; }
  .profileRow {
    display: flex;
    align-items: center;
    min-height: 50px; }
    .profileRow span {
      display: inline-block; }
      .profileRow span:first-child {
        min-width: 157px; }
  .profileContent {
    padding-left: 20px;
    padding-right: 20px; }
  .profile hr {
    text-align: center; }
  .profile--save {
    margin-top: 10px;
    margin-left: 20px; }
  @media screen and (max-width: 400px) {
    .profileRow {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 10px; }
      .profileRow span {
        margin-right: auto; }
      .profileRow input[type=text] {
        width: 100%; } }

.eaChat {
  background-color: #f5f4f7;
  border-radius: 20px;
  display: none;
  z-index: 1000; }
  @media screen and (max-width: 800px) {
    .eaChat {
      border-radius: 0; } }
  .eaChat.-active {
    display: block; }
  .eaChat iframe {
    background-color: #f5f4f7;
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 20px;
    border: 3px solid #ffffff;
    box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.2);
    backface-visibility: hidden; }
    @media screen and (max-width: 800px) {
      .eaChat iframe {
        border-radius: 0; } }
  .eaChat.-homepage {
    max-width: 860px;
    margin: 0 auto;
    border-radius: 50px;
    height: 440px;
    position: relative;
    width: 100%; }
    @media screen and (max-width: 500px) {
      .eaChat.-homepage {
        position: fixed;
        top: 0;
        bottom: 0;
        width: 100%;
        right: 0;
        left: 0;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        max-width: inherit;
        max-height: inherit;
        margin: unset;
        border-radius: 0; } }
  .eaChat.-minimize {
    height: 49px !important;
    top: auto !important; }
  .eaChat.-fixed {
    position: fixed;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    bottom: 20px;
    right: 20px;
    max-width: 500px;
    max-height: 550px; }
    @media screen and (max-width: 800px) {
      .eaChat.-fixed {
        right: 20px;
        bottom: 20px; } }
    @media screen and (max-width: 500px) {
      .eaChat.-fixed {
        position: fixed;
        top: 0;
        bottom: 0;
        width: 100%;
        right: 0;
        left: 0;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        max-width: inherit;
        max-height: inherit; } }

.intro .boxImage {
  margin: 0 20px 0 0; }
  @media screen and (max-width: 800px) {
    .intro .boxImage {
      margin: 0 15px 0 0; } }

.iheader h1 {
  margin-top: 0; }

.iheaderStripe {
  padding-top: 8px; }
  .iheaderStripe h1 {
    color: #4A3559; }
  @media screen and (max-width: 800px) {
    .iheaderStripe {
      padding-top: 4px; } }

.ilaws {
  width: calc(100% - 82px);
  padding-top: 30px; }
  @media screen and (max-width: 800px) {
    .ilaws {
      padding-top: 10px; } }
  .ilawsFlex {
    padding-top: 20px;
    padding-bottom: 20px; }
    @media screen and (max-width: 800px) {
      .ilawsFlex {
        padding-top: 10px;
        padding-bottom: 20px; } }
  .ilawsRequest {
    flex-direction: column; }
    .ilawsRequest > * {
      margin-bottom: 10px;
      margin-right: auto; }
    .ilawsRequest span:nth-child(2) {
      margin-bottom: 20px; }
    .ilawsRequest span {
      display: block;
      padding-left: 0 !important; }
  .ilawsConnect {
    padding-bottom: 20px; }
    @media screen and (max-width: 800px) {
      .ilawsConnect {
        padding-top: 0;
        padding-bottom: 10px; } }
    .ilawsConnect textarea {
      flex-basis: 520px;
      min-height: 173px; }

.ioffline {
  width: 480px; }
  .ioffline textarea {
    height: 140px; }
  .iofflineText {
    display: block;
    margin-bottom: 10px;
    margin-top: 10px; }
  .ioffline .flex {
    padding-top: 10px;
    align-items: center; }

.switchWrapper {
  display: none; }

.ptContent ol {
  counter-reset: item; }
  .ptContent ol li {
    display: block; }
  .ptContent ol li:before {
    content: counters(item, ".") ". ";
    counter-increment: item;
    display: inline-block; }

.ptContent ol {
  margin-bottom: 20px;
  padding-left: 25px; }
  .ptContent ol > li {
    margin-bottom: 10px;
    font-weight: bold; }
    .ptContent ol > li:first-child {
      margin-top: 10px; }
    .ptContent ol > li:before {
      margin-left: -25px;
      width: 25px; }
    .ptContent ol > li > ol {
      padding-left: 35px; }
      .ptContent ol > li > ol > li {
        font-weight: normal !important; }
        .ptContent ol > li > ol > li:before {
          margin-left: -35px;
          width: 35px; }
        .ptContent ol > li > ol > li > ol {
          padding-left: 45px; }
          .ptContent ol > li > ol > li > ol > li:before {
            margin-left: -45px;
            width: 45px; }

.ptContent ol.-longer > li > ol {
  padding-left: 50px; }
  .ptContent ol.-longer > li > ol > li:before {
    margin-left: -50px;
    width: 50px; }
  .ptContent ol.-longer > li > ol > li > ol {
    padding-left: 55px; }
    .ptContent ol.-longer > li > ol > li > ol > li:before {
      margin-left: -55px;
      width: 55px; }

.contact .boxLine {
  padding-bottom: 13px; }

.contactItem {
  margin-bottom: 50px; }

.payment .caseArrow {
  margin-left: unset !important; }

.paymentStatus {
  color: #66142D;
  margin-left: auto;
  padding-right: 40px; }
  @media screen and (max-width: 800px) {
    .paymentStatus {
      padding-right: 20px;
      text-align: right; } }

.pays {
  display: flex; }
  @media screen and (max-width: 800px) {
    .pays {
      flex-direction: column;
      align-items: center; } }
  .pays .pay {
    flex-basis: 33.33%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-width: 1px 0 1px 1px;
    border-style: solid;
    border-image: linear-gradient(to top, #fff 0%, #fff 5%, #FFA11F 45%, #FFA11F 55%, #fff 95%, #fff 100%) 1 100%;
    position: relative; }
    @media screen and (max-width: 800px) {
      .pays .pay {
        max-width: 400px;
        min-height: 130px;
        width: 100%;
        border-width: 0; }
        .pays .pay:after {
          content: "";
          background: linear-gradient(to right, #fff 0, #fff 1%, #ffa11f 25%, #ffa11f 75%, #fff 99%, #fff 100%);
          display: block;
          height: 1px;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0; }
        .pays .pay:last-child:after {
          display: none; } }
    .pays .pay:first-child {
      border-style: none; }
    .pays .payTitle {
      margin-top: 0;
      display: block;
      margin-bottom: 20px;
      font-size: 18px; }
    .pays .payQr img {
      width: 100%; }
    .pays .payContent {
      margin-bottom: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 16px; }
      .pays .payContent.-small {
        font-size: 12px;
        width: 100%; }
        .pays .payContent.-small > div {
          margin-right: auto; }

.paymentDescription {
  padding-top: 15px;
  padding-left: 20px;
  padding-bottom: 14px;
  position: relative; }
  .paymentDescription:after {
    content: "";
    background: linear-gradient(to right, #fff 0%, #fff 1%, #FFA11F 25%, #FFA11F 75%, #fff 99%, #fff 100%);
    display: block;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; }
  .paymentDescription div {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px; }
    .paymentDescription div span {
      font-weight: normal; }

.paidStatus {
  padding: 20px;
  padding-right: 45px;
  margin-bottom: 2px;
  position: relative; }
  .paidStatus.-success {
    background-color: #ffc49f; }
  .paidStatus.-error {
    background-color: #ff0000;
    color: #ffffff; }
  .paidStatus--close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer; }
  .paidStatus.-closed {
    display: none; }

.paymentMethod {
  display: block;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #B9AEC3; }
  .paymentMethod img {
    width: 60px;
    margin-right: 15px; }
  .paymentMethod > * {
    vertical-align: middle; }

#paymentModal .modalContent {
  text-align: left; }
  #paymentModal .modalContent h1 {
    text-align: center; }

.stripeLegal h2 {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-top: 30px; }
  @media screen and (max-width: 800px) {
    .stripeLegal h2 {
      padding-top: 15px; } }

.stripeLegalST {
  color: #FFA11F;
  text-align: center;
  display: block;
  width: 100%;
  font-size: 13px;
  padding-top: 5px;
  text-transform: uppercase;
  letter-spacing: 0.5px; }
  @media screen and (max-width: 400px) {
    .stripeLegalST {
      margin-top: 10px; } }

.stripeLegal .personSlider {
  margin-top: 20px; }

.eaChat p, .chat__message p {
  margin: 0;
  padding: 0; }

.eaChat button:defined,
.eaChat button[disabled], .chat__message button:defined,
.chat__message button[disabled] {
  opacity: 0.5; }

body.-minimized .chat__submit {
  display: none; }

.chat__header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #f5f4f7;
  padding-left: 20px;
  padding-right: 20px;
  height: 45px;
  cursor: pointer; }
  .chat__header h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: padding-left 0.2s ease-in-out; }
    .chat__header h1.-paddingLeft {
      padding-left: 40px; }
    .chat__header h1.-paddingRight {
      padding-right: 80px; }
  .chat__header:after {
    content: "";
    background: linear-gradient(to right, #f5f4f7 0%, #f5f4f7 5%, #c0bfc1 15%, #c0bfc1 85%, #f5f4f7 95%, #f5f4f7 100%);
    display: block;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; }
  .chat__header.-disablecursor {
    cursor: auto !important; }

.chat__newmessage {
  width: 16px;
  height: 16px;
  background-color: red;
  position: absolute;
  left: 30px;
  border-radius: 50%;
  animation: pulse_animation 2s infinite linear;
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  backface-visibility: hidden; }

@keyframes pulse_animation {
  0% {
    transform: scale(1); }
  30% {
    transform: scale(1); }
  40% {
    transform: scale(1.5); }
  50% {
    transform: scale(1); }
  60% {
    transform: scale(1); }
  70% {
    transform: scale(1.2); }
  80% {
    transform: scale(1); }
  100% {
    transform: scale(1); } }

.chat_minimize {
  display: flex;
  width: 34px;
  height: 34px;
  position: absolute;
  right: 64px;
  cursor: pointer;
  z-index: 99999;
  background-color: #f5f4f7;
  transition: background-color 0.1s linear; }
  .chat_minimize:hover {
    background-color: #dad6e2; }
  .chat_minimize img {
    display: block;
    width: 18px;
    height: 18px;
    margin: auto; }

.chat__close {
  display: flex;
  width: 34px;
  height: 34px;
  position: absolute;
  right: 30px;
  cursor: pointer;
  z-index: 99999;
  background-color: #f5f4f7;
  transition: background-color 0.1s linear; }
  .chat__close:hover {
    background-color: #dad6e2; }
  .chat__close img {
    display: block;
    width: 18px;
    height: 18px;
    margin: auto; }

chat-conversation {
  height: calc(100% - 45px) !important; }

.chat__conversation {
  background-color: #f5f4f7; }

.scroll-panel-container {
  border-bottom: 1px solid #dad5dc; }

.chat__messages__scroll {
  flex-basis: auto !important; }

.chat__message {
  display: flex;
  margin-bottom: 10px;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px; }
  .chat__message:first-child {
    margin-top: 10px; }
  .chat__message__avatar {
    margin-right: 10px; }
    .chat__message__avatar img {
      display: block;
      width: 50px;
      height: 50px;
      border-radius: 50%; }
  .chat__message__content {
    display: flex;
    flex-direction: column;
    word-break: break-all; }
    .chat__message__content .chat__message__meta {
      display: flex;
      margin-top: 13px; }
      .chat__message__content .chat__message__meta .chat__message__user {
        margin-right: 10px;
        color: #66142D;
        font-weight: bold; }
      .chat__message__content .chat__message__meta .chat__message__timestamp {
        color: #6D5E76;
        font-weight: 300; }
    .chat__message__content .chat__message__text {
      color: #4B3956;
      line-height: 20px; }

.chat__compose {
  display: flex;
  flex-direction: row;
  background-color: #ffffff; }

.chat__textarea {
  flex: 1; }
  .chat__textarea label {
    display: none; }
  .chat__textarea textarea {
    display: block;
    width: 100%;
    border: none;
    resize: none;
    min-height: 50px;
    height: 50px;
    font-size: 14px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    transition: height 0.1s ease-in-out; }
    .chat__textarea textarea:focus {
      outline: none; }
    .chat__textarea textarea.-bigger {
      height: 150px; }

.chat__submit {
  align-self: flex-end;
  background-color: #ffffff; }
  .chat__submit button {
    background-image: linear-gradient(to right, #F8B800 0%, #F8B800 51%, #F51E30 100%);
    background-size: 200% auto;
    transition: 0.3s all ease-in-out !important;
    background-position: right center;
    border: none;
    color: #ffffff;
    font-size: 16px;
    height: 38px;
    border-radius: 20px;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    color: transparent;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 6px; }
    .chat__submit button:hover {
      background-position: 40% center; }
    .chat__submit button:before {
      content: '';
      display: table-cell;
      vertical-align: middle;
      width: 30px;
      height: auto;
      background-image: url(../img/icons/small/poslat.svg);
      background-repeat: no-repeat;
      background-size: 24px auto;
      position: absolute;
      left: 13px;
      top: 8px;
      bottom: 0;
      overflow: hidden; }

.chat__info, .chat__message__action {
  margin: 10px 0 10px 80px; }

.chat__system__info {
  margin: 10px 0 10px 40px;
  font-weight: bold; }

.chat__info__user {
  font-style: italic;
  animation-name: pulse;
  animation-duration: 800ms;
  animation-delay: 0ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse; }

@keyframes pulse {
  0% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

.designWrapper {
  width: 100%;
  background-color: #f8f8f8;
  padding-left: 50px;
  padding-right: 50px;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000; }

.designWrapper.-padding {
  padding-top: 20px;
  padding-bottom: 20px; }

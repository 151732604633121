body {
  position: relative;
  z-index: 0;
  font-size: 16px;
  line-height: 1.3;
  @media screen and (max-width: $smallMobile) {
    font-size: 14px;
  }
}

.content {
  margin: 0 auto;
}
.content.-inner {
  width: 100%;
  max-width: 965px;
  @media screen and (max-width: $tablet) {
    max-width: 750px;
  }
  @media screen and (max-width: $mobile) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (max-width: $smallMobile) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.content.-innerBig {
  width: 100%;
  max-width: 1020px;
}

section {
  padding-top: 30px;
  padding-bottom: 25px;
  @media screen and (max-width: $mobile) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.img__wrap {
  background-color: #ccc;
  position: relative;
  overflow: hidden;
  img {
    position:relative;
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    max-width: 100%;
  }
}


.hide-on-mobile {
  @media screen and (max-width: $mobile) {
    display: none !important;
  }
}
.hide-over-mobile {
  @media screen and (min-width: $mobile + 1px) {
    display: none !important;
  }
}

.hide-on-tablet {
  @media screen and (max-width: $tablet) {
    display: none !important;
  }
}
.hide-over-tablet {
  @media screen and (min-width: $tablet + 1px) {
    display: none !important;
  }
}






::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@include input-placeholder{
  color: $grey;
};



.intro {
  .boxImage {
    margin: 0 20px 0 0;
    @media screen and (max-width: $mobile) {
      margin: 0 15px 0 0;
    }
  }

}

.iheader {
  h1 {
      margin-top: 0;
    }
    &Stripe {
      h1 {
        color: $basicTextColor;
      }
      padding-top: 8px;
      @media screen and (max-width: $mobile) {
        padding-top: 4px;
      }
    }
}

.ilaws {
  width: calc(100% - 82px);
  padding-top: 30px;
  @media screen and (max-width: $mobile) {
    padding-top: 10px;
  }
  &Flex {
    padding-top: 20px;
    padding-bottom: 20px;
    @media screen and (max-width: $mobile) {
      padding-top: 10px;
      padding-bottom: 20px
    }
  }
  &Request {
      & > * {
        margin-bottom: 10px;
        margin-right: auto;
      }
      flex-direction: column;
      span:nth-child(2) {
        margin-bottom: 20px;
      }
      span {
        display: block;
        padding-left: 0 !important;
      }
    .selectInline {
      //min-width: 348px;
      @media screen and (max-width: $mobile) {

      }
    }
  }
  &Connect {
    //padding-top: 20px;
    padding-bottom: 20px;
    @media screen and (max-width: $mobile) {
      padding-top: 0;
      padding-bottom: 10px;
    }
    textarea {
      flex-basis: 520px;
      min-height: 173px;
    }
  }
}

.ioffline {
  width: 480px;
  textarea {
    height: 140px;
  }
  &Text {
    display: block;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .flex {
    padding-top: 10px;
    align-items: center;
  }
}

$toastPadding: 16px;

.toast__container {
  position: fixed;
  top: 34px;
  right: 34px;
  max-width: calc(100% - 34px);
  z-index: 10000;
}

@keyframes appearToast {
  0% {
    opacity: 0;
    top: 50vh;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
@keyframes hideToast {
  0% {
    opacity: 1;
    max-height: 30vh;
    padding-top: $toastPadding;
    padding-bottom: $toastPadding;
    margin-bottom: $toastPadding;
  }
  50% {
    padding-top: $toastPadding;
    padding-bottom: $toastPadding;
    margin-bottom: $toastPadding;
  }
  100% {
    opacity: 0;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}


.toast {
  position: relative;
  color: white;
  border-radius: 5px;
  padding: $toastPadding;
  animation: appearToast 0.6s;
  margin-bottom: $toastPadding;
  width: 30vw;
  max-width: 100%;
  min-width: 20em;
  max-height: 30vh;
  background-color: $basicTextColor;
  *:last-child {
    margin-bottom: 0;
  }
  &.-hide {
    animation: hideToast 0.6s;
    overflow: hidden;
    opacity: 0;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

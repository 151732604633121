.eaChat, .chat__message {
  p {
    margin: 0;
    padding: 0;
  }
  button:defined,
  button[disabled] {
    opacity: 0.5;
  }
}

body {
  &.-minimized {
    .chat__submit {
      display: none;
    }
  }
}

$chatPadding: 20px;

.chat__header {
  display: flex;
  align-items: center;
  justify-content: center;
  //box-shadow: 0px 4px 5px -4px rgba(0,0,0,0.1);
  position: relative;
  background-color: #f5f4f7;
  padding-left: 20px;
  padding-right: 20px;
  height: 45px;
  cursor: pointer;
  h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: padding-left 0.2s ease-in-out;
    &.-paddingLeft {
      padding-left: 40px;
    }
    &.-paddingRight {
      padding-right: 80px;
    }
  }
  &:after {
    content:"";
    background: linear-gradient(to right, #f5f4f7 0%, #f5f4f7 5%, #c0bfc1 15%, #c0bfc1 85%, #f5f4f7 95%, #f5f4f7 100%);
    display: block;
    height:1px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &.-disablecursor {
    cursor: auto !important;
  }
}
.chat__newmessage {
  width: 16px;
  height: 16px;
  background-color: red;
  position: absolute;
  left: 30px;
  border-radius: 50%;
  animation: pulse_animation 2s infinite linear;
  box-shadow: 0 0 0 rgba(204,169,44, 0.4);
  backface-visibility: hidden;
}

@keyframes pulse_animation {
  0% { transform: scale(1); }
  30% { transform: scale(1); }
  40% { transform: scale(1.5); }
  50% { transform: scale(1); }
  60% { transform: scale(1); }
  70% { transform: scale(1.2); }
  80% { transform: scale(1); }
  100% { transform: scale(1); }
}


.chat_minimize {
  display: flex;
  width: 34px;
  height: 34px;
  position: absolute;
  right: 64px;
  cursor: pointer;
  z-index: 99999;
  background-color: #f5f4f7;
  transition: background-color 0.1s linear;
  &:hover {
    background-color: darken(#f5f4f7, 10%);
  }
  img {
    display: block;
    width: 18px;
    height: 18px;
    margin: auto;
  }
}

.chat__close {
  display: flex;
  width: 34px;
  height: 34px;
  position: absolute;
  right: 30px;
  cursor: pointer;
  z-index: 99999;
  background-color: #f5f4f7;
  transition: background-color 0.1s linear;
  &:hover {
    background-color: darken(#f5f4f7, 10%);
  }
  img {
    display: block;
    width: 18px;
    height: 18px;
    margin: auto;
  }
}

chat-conversation {
  height: calc(100% - 45px) !important;
}
//e1dde3
.chat__conversation {
  background-color: #f5f4f7;
}

.scroll-panel-container {
  border-bottom: 1px solid #dad5dc;
}

.chat__messages__scroll {
  flex-basis: auto !important;
}

.chat__message {
  display: flex;
  margin-bottom: 10px;
  align-items: flex-start;
  padding-left: $chatPadding;
  padding-right: $chatPadding;
  &:first-child {
    margin-top: 10px;
  }
  &__avatar {
    margin-right: 10px;
    img {
      display: block;
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    word-break: break-all;
    .chat__message__meta {
      display: flex;
      margin-top: 13px;
      .chat__message__user {
        margin-right: 10px;
        color: $headersColor;
        font-weight: bold;
      }
      .chat__message__timestamp {
        color: #6D5E76;
        font-weight: 300;
      }
    }
    .chat__message__text {
      color: #4B3956;
      line-height: 20px;

    }
  }
}

//wrapper a v nem je textarea a submit
.chat__compose {
  display: flex;
  flex-direction: row;
  background-color: $white;
}

.chat__textarea {
  flex: 1;
  label {
    display: none;
  }
  textarea {
    display: block;
    width: 100%;
    border: none;
    resize: none;
    min-height: 50px;
    height: 50px;
    font-size: 14px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    transition: height 0.1s ease-in-out;
    &:focus {
      outline: none;
    }
    &.-bigger {
      height: 150px;
    }
  }
}

.chat__submit {
  align-self: flex-end;
  background-color: $white;
  button {
    //background: linear-gradient(to right, $gradientRed , $gradientYellow);
    background-image: linear-gradient(to right, $gradientYellow 0%, $gradientYellow 51%, $gradientRed 100%);
    background-size: 200% auto;
    transition: 0.3s all ease-in-out !important;
    background-position: right center;

    border: none;
    color: $white;
    font-size: 16px;
    height: 38px;
    border-radius: 20px;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    color: transparent;
    //width: 50px;

    //text-indent: 100%;
    //white-space: nowrap;
    //overflow: hidden;

    //display: table;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 6px;
    //margin-bottom: 2px;
    &:hover {
      background-position: 40% center;
    }

    &:before {
      content: '';
      display: table-cell;
      vertical-align: middle;
      width: 30px;
      height: auto;
      background-image: url(../img/icons/small/poslat.svg);
      background-repeat: no-repeat;
      background-size: 24px auto;
      position: absolute;
      left: 13px;
      top: 8px;
      bottom: 0;
      overflow: hidden;
    }
  }
}

.chat__info, .chat__message__action {
  margin: 10px 0 10px 80px;
}
.chat__system__info {
  margin: 10px 0 10px 40px;
  font-weight: bold;
}

.chat__info__user {
  font-style: italic;
  animation-name: pulse;
  animation-duration: 800ms;
  animation-delay: 0ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}

@keyframes pulse {
  0% { opacity: 0.5}
  100% { opacity: 1}
}




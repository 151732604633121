.beforeFooter {
  &.-homepage {
    height: 170px;
  }
  &.-other {
    height: 300px;
    display: flex;
    margin-top: 100px;
    img {
      height: 200px;
      max-width: 90%;
      margin: auto;
    }
  }
}
$triviBackground: #08b0cd;
$triviHeight: 90px;
$triviCirceBackground: #ffe01b;

$triviRespMobile: 860px;

.trivi {
  color: $white;
  height: $triviHeight;
  background-color: $triviBackground;
  overflow: hidden;
  @media screen and (max-width: $triviRespMobile) {
    height: auto;
    padding-top: 5px;
    padding-bottom: 10px;
  }
  p {
    color: $white;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    @media screen and (max-width: $triviRespMobile) {
      font-size: 14px;
      br {
        display: none;
      }
    }
  }
  .content {
    display: flex;
    @media screen and (max-width: $tablet) {
      max-width: calc(100% - 40px) !important;
    }
    @media screen and (max-width: $triviRespMobile) {
      flex-direction: column;
    }
    @media screen and (max-width: $mobile) {
      padding-left: 0;
      padding-right: 0;
    }

  }
  &Offer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: $triviHeight;
    @media screen and (max-width: $triviRespMobile) {
      margin: auto;
      height: auto;
    }
    span {
      display: flex;
      align-items: center;
      width: 58px;
      height: 58px;
      min-width: 58px;
      justify-content: center;
      border: 3px solid $white;
      border-radius: 50%;
      font-size: 26px;
      margin-right: auto;

    }
    p {
      margin-left: 20px;
      @media screen and (max-width: $triviRespMobile) {
        margin-left: 10px;
      }
    }
  }
  &Discount {
    margin-left: auto;
    height: $triviHeight;
    display: flex;
    align-items: center;
    @media screen and (max-width: $triviRespMobile) {
      margin: auto;
      height: auto;
    }
    p {
      text-align: right;
      margin-right: 10px;
      font-weight: bold;
      @media screen and (max-width: $triviRespMobile) {
        text-align: left;
      }
    }

  }
  &Circle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $triviCirceBackground;
    flex-basis: 120px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    top: 58%;
    transform: translateY(-50%);
    flex-direction: column;
    @media screen and (max-width: $triviRespMobile) {
      width: 70px;
      min-width: 70px;
      flex-basis: 70px;
      height: 70px;
      min-height: 70px;
      transform: translateY(0);
      top: 0;
    }
    span {
      color: $black;
      &:nth-child(1) {
        font-weight: bold;
        font-size: 30px;
        padding-top: 10px;
        font-weight: bold;
        @media screen and (max-width: $triviRespMobile) {
          padding-top: 0;
          font-size: 22px;
        }
        @media screen and (max-width: $smallMobile) {
          font-size: 20px;
          letter-spacing: -1px;
        }
      }
      &:nth-child(2) {
        text-transform: uppercase;
        font-size: 14px;
        @media screen and (max-width: $triviRespMobile) {
          padding-top: 0;
          font-size: 12px;
        }
      }
    }
  }
}
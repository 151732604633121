footer, .footer {
  background-color: #F5F4F7;
  padding-top: 30px;
  padding-bottom: 40px;
  nav {
    display: block;
    width: 300px;
    margin-right: 20px;
    ul {
      //align-self: flex-end;
      @include liReset();
      li {
        a {
          display: block;
          font-size: 13px;
          text-decoration: none;
          padding: 8px 0;
          border-bottom: 1px solid $accordeonBorderColor;
          &:hover {
            border-bottom: 1px solid $orange;
          }
        }
      }
    }
  }

  &Logo {
    display: block;
    margin-left: auto;
    margin-bottom: auto;
    justify-self: flex-start;
    width: 200px;
    &.-en {
      width: 190px;
    }
  }


  &Stripe {
    background-image: linear-gradient(to right, #392648, #424242);
    width: 100%;
    height: 64px;
  }
}

footer, .footer {
  @media screen and (max-width: $mobile) {
    .content {
      flex-direction: column;
      justify-content: center;
      nav {
        margin: auto;
        text-align: center;
      }
      .footerLogo {
        margin: 20px auto 0 auto;
      }
    }
  }
}


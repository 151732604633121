.preloader {
  display: block;
  position: absolute;
  top:0;
  left:0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0;
  -webkit-transition: opacity 0.4s linear, z-index 0.5s linear;
  -o-transition: opacity 0.4s linear, z-index 0.5s linear;
  transition: opacity 0.4s linear, z-index 0.5s linear;
  z-index: -9;
}

.preloader.-active {
  opacity: 0.3;
  z-index: 10000;
}

.spinnerWrap {
  position: relative;
  height: 100%;
  width: 100%;

}

.spinner {
  margin: 0 auto 20px auto;
  height: 40px;
  width: 40px;
  -webkit-animation: rotate 0.8s infinite linear;
  animation: rotate 0.8s infinite linear;
  border: 4px solid $orange;
  border-right-color: transparent;
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  /* bottom: 0; */
  z-index: 10001;
}

@-webkit-keyframes rotate {
  0%    { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100%  { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes rotate {
  0%    { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100%  { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.status {
  padding-left: 10px;
  &:before {
    display: inline-block;
    content: ' ';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 6px;
    position: relative;
    bottom: 1px;
  }


  &.-online {
    color: $lightGreen;
    &:before {
      background-color: $lightGreen;
    }
  }
  &.-offline {
    color: $gradientRed;
    &:before {
      background-color: $gradientRed;
    }
  }
}
/*
.button {
  display: inline-flex;
  height: 40px;
  max-height: 40px;
  line-height: 35px;
  color: $orange;
  font-size: 18px;
  border: 2px solid $orange;
  border-radius: 20px;
  padding-right: 25px;
  padding-left: 25px;
  text-decoration: none;
  position:relative;
  transition: 0.2s all;
  @media screen and (max-width: $mobile) {
    height: 30px;
    line-height: 25px;
    font-size: 14px;
  }
  svg, img {
    display: inline;
    max-height: 25px;
    align-self: center;
    padding-right: 25px;
    @media screen and (max-width: $mobile) {
      //max-height: 15px;
      padding-right: 15px;
    }
  }
  &.-svg {
    max-height: 40px;
    @media screen and (max-width: $mobile) {
      //max-height: 20px;
    }

  }
  &:hover {
    background-color: $orange;
    color: $white;
    svg path, svg polygon {
      fill: $white;
    }
  }

  &.-icoLogin {
    svg {
      position: relative;
      max-height: 20px;
      @media screen and (max-width: $mobile) {
        max-height: 15px;
      }
    }
  }

}

.button.-icon {
  padding-left: 10px;
}

.button.-small {
  font-size: 16px;
  height: 32px;
  line-height: 28px;
}

.button.-full {
  background: linear-gradient(to right, $gradientRed , $gradientYellow);
  border: none;
  color: $white;
  //transition: 0.2s opacity;
  &:hover {
   //background: linear-gradient(to right, darken($gradientRed,10%) , darken($gradientYellow,10%));
    opacity: 0.9;
  }
}

*/

.button {
  display: inline-block;
  color: $orange;
  font-size: 18px;
  border: 2px solid $orange;
  border-radius: 20px;
  padding-right: 25px;
  padding-left: 25px;
  text-decoration: none;

  transition: all 0.3s ease 0s;
  vertical-align: middle;
  line-height: 33px;
  font-weight: normal;
  background-color: transparent;
  cursor: pointer;
  height: 39px;
  max-height: 39px;
  @media screen and (max-width: 380px) {
    font-size: 14px;
    padding: 0 10px 2px 10px;
    line-height: 26.5px;
    height: 31px;
    max-height: 31px;
  }
  svg path, svg polygon {
    transition: all 0.3s ease 0s;
  }


  &:hover {
    background-color: $orange;
    color: $white;
    svg path, svg polygon {
      fill: $white;
      stroke: $white;
    }
  }

  svg, img {
    display: inline-block;
    height: 18px;
    vertical-align: middle;
    margin-right: 20px;
    margin-left: -10px;
    position: relative;
    bottom: 2px;
    @media screen and (max-width: 380px) {
      height: 16px;
      margin-right: 5px;
      margin-left: 0px;
    }
  }

}


.button.-full {
  //background: linear-gradient(to left, $gradientRed , $gradientYellow);
  background-image: linear-gradient(to right, $gradientYellow 0%, $gradientYellow 51%, $gradientRed 100%);
  background-size: 200% auto;
  border: none;
  color: $white;
  transition: 0.3s all ease-in-out !important;
  background-position: right center;
  line-height: 37px;
  //transition: 0.2s opacity;
  //transition-timing-function: ease-in !important;
  &:hover {
    //background: linear-gradient(to right, darken($gradientRed,10%) , darken($gradientYellow,10%));
    //opacity: 0.9;
    //background-position: left center;
    background-position: 40% center;
  }
  @media screen and (max-width: 380px) {
    line-height: 29px;
  }
  //padding-top: 7px;
  //padding-bottom: 8px;
}

.button.-disabled {
  color: #e5e5e5;
  border: 2px solid #e5e5e5;
  pointer-events: none;
  svg path, svg polygon {
    fill: #e5e5e5;
  }
  &:hover {
    svg path, svg polygon {
      fill: #e5e5e5;
    }
  }
}

.personSlider {
  height: 280px;
  position: relative;
  width: 100%;
  display: block;
  @media screen and (max-width: $mobile) {
    height: 200px;
  }
  &Circles {
    text-align: center;
    padding-bottom: 20px;
    .circle {
      display: inline-block;
      width: 9px;
      height: 9px;
      background-color: #d5d0d9;
      border-radius: 50%;
      margin-left: 15px;
      margin-right: 15px;
      &.-full {
        background-color: $orange;
      }
      cursor: pointer;
    }
  }
}
.personSlide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  transition: 1s opacity;
  flex-direction: row;
  justify-content: center;
  @media screen and (max-width: $mobile) {
    .box {
      padding-left: 10px;
      padding-right: 10px;
      h4 {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
    @media screen and (max-width: 550px) {
    flex-direction: column;
    display: flex;
    justify-content: center;
    .box {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      span {
        display: none;
      }
      div {
        margin-top: auto;
        margin-bottom: auto;
      }
      h4 {
        font-size: 20px;
      }
      &Image {
        margin: 0 20px;
      }
    }
  }
  @media screen and (max-width: 450px) {
    .box {
      padding-left: 0;
      justify-content: left;
      &Image {
        margin-left: 0;
      }
    }
  }
  &.-visible {
    //opacity: 1 !important;
    z-index: 10 !important;
  }
}
.modifiedAlert {
  width: 14px;
  height: 14px;

  position: absolute;
  box-shadow: 0 0 0 rgba(204,169,44, 0.4);
  backface-visibility: hidden;
  display: inline-block;
  bottom: 3px;
}
.caseLabel .modifiedAlert {
  position: relative;
  //width: 10px;
  //height: 10px;
  margin-right: 10px;
  min-width: 10px;
  display: inline-block;
  vertical-align: top;
  margin-bottom: auto;
  margin-top: 9px;
}
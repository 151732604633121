.eaChat {
  background-color: #f5f4f7;
  border-radius: 20px;
  display: none;
  z-index: 1000;
  @media screen and (max-width: $mobile) {
    border-radius: 0;
  }
  &.-active {
    display: block;
  }
  iframe {
    background-color: #f5f4f7;
    width: 100%;height: 100%;
    display: block;
    border-radius: 20px;
    border: 3px solid $white;
    box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.2);
    backface-visibility: hidden;
    @media screen and (max-width: $mobile) {
      border-radius: 0;
    }
  }

  &.-homepage {
    max-width: 860px;
    margin: 0 auto;
    border-radius: 50px;
    height: 440px;
    position: relative;
    width: 100%;
    @media screen and (max-width: 500px) {
      position: fixed;
      top: 0;
      bottom: 0;
      width: 100%;
      right: 0;
      left: 0;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      max-width: inherit;
      max-height: inherit;
      margin: unset;
      border-radius: 0;
    }
  }

  &.-minimize {
    height: 49px !important;
    top: auto !important;
  }

  &.-fixed {
    position: fixed;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    bottom: 20px;
    right: 20px;
    max-width: 500px;
    max-height: 550px;

    @media screen and (max-width: $mobile) {
      //bottom: auto;
      //width: 100%;
      right: 20px;
      bottom: 20px;
    }

    @media screen and (max-width: 500px) {
      position: fixed;
      top: 0;
      bottom: 0;
      width: 100%;
      right: 0;
      left: 0;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      max-width: inherit;
      max-height: inherit;
    }

  }
}
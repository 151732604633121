.flex {
  display: flex;
  //justify-content: space-between;
  &Item {
    display: flex;
    flex-direction: column;
    &.-c3 {
      flex-basis: calc(33.33% - 12px);
    }
    &.-c2 {
      flex-basis: calc(50% - 10px);
    }
  }
}
.flex.-aCenter {
  align-items: center;
}

.flex.-space-between {
  justify-content: space-between;
}
.spacer {
  flex-grow: 1!important;
  flex-shrink: 1!important;
}

.-mLeft {
  margin-left: auto;
}
.-mAuto {
  margin: auto;
}
$lowerFirst: 1000px;
$lowerSecond: 900px;

.caseTitle {
  display: flex;
  justify-content: center;
  flex-direction: column;
  & > * {
    align-self: center;
  }
  margin-bottom: 35px;
}



$casePaddingLeftRight: 20px;

.case {
  display: block;
  width: 850px;
  @media screen and (max-width: 870px) {
    width: 100%;
  }
  margin: 0 auto;
  transition: width 0.2s;
  //margin-bottom: 20px;
  margin-top: 10px;
  //padding-bottom: 40px;

  h5 {
    //margin-bottom: 10px;
  }

  &Label {
    position: relative;
    border-top: 1px solid $inputBorderColor;
    border-bottom: 1px solid $inputBorderColor;
    background-color: $footerColor;
    padding-left: $casePaddingLeftRight;
    padding-right: $casePaddingLeftRight;
    cursor: pointer;
    &.-noclick {
      cursor: auto !important;
    }
  }
  &Name {
    display: flex;
    font-size: 20px;
    color: $headersColor;
    align-items: center;
    padding-top: 17px;
    padding-bottom: 5px;
    img.caseArrow {
      height: 10px;
      position: relative;
      bottom: -2px;
      transition: transform 0.2s;
      margin-left: auto;
    }
    &First {
      align-self: flex-start;
    }
  }
  &Data {
    padding-bottom: 15px;
    font-size: 14px;
    &Download {
      float: right;
      padding-right: 60px;
      padding-left: 20px;
      @media screen and (max-width: 800px) {
        padding-right: 40px;
      }
      img {
        height: 20px;
        display: inline-block;
        cursor: pointer;
      }
    }
  }

  &Content {
    padding-left: $casePaddingLeftRight;
    padding-right: $casePaddingLeftRight;
    display: flex;
    flex-direction: row;
    padding-bottom: 40px;
    transition: all 0.5s;
    min-height: 200px;
    @media screen and (max-width: $lowerSecond) {
      flex-direction: column;
      padding-bottom: 20px;
    }
    &Wrapper {
      max-height: 0;
      overflow: hidden;
      transition: all 0.3s;
      position: relative;
    }
  }
  transition: all 0.5s;

  &.-open {
    width: 100%;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    margin-top: 20px;
    margin-bottom: 10px;
    .caseLabel {
      background-color: #ffc49f;
    }
    .caseContentWrapper {
      max-height: 1000px;
    }
    .caseArrow {
      transform: rotate(180deg);
    }
  }


  /* LEVA CAST dokumenty, a faktury */
  &Left {
    flex-basis: 590px;
    @media screen and (max-width: $lowerFirst) {
      flex-basis: 500px;
    }
    @media screen and (max-width: $lowerSecond) {
      flex-basis: auto;
    }
  }
  &Doku {
    display: flex;
    min-height: 63px;
    align-content: center;
    justify-content: center;
    a {
      margin-left: auto;
      margin-bottom: auto;
      margin-top: auto;
      @media screen and (max-width: $lowerSecond) {
        font-size: 0;
        padding-left: 15px;
        padding-right: 15px;
        svg {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }
    }
    &Date {
      min-width: 85px;
    }
  }
  &Table {
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    $caseTableSpacing: 7px;

    margin: 7px -$caseTableSpacing;
    display: block;
    min-height: 150px;
    @media screen and (max-width: $lowerSecond) {
      min-height: auto;
    }
    table {
      border-collapse: separate;
      border-spacing: $caseTableSpacing 0;
      width: 100%;
      tr {
        cursor: pointer;
      }
      tr:first-child td {
        border-top: 1px solid #d5d0d8;
      }
      td {
        border-bottom: 1px solid #d5d0d8;
        //border-left: 5px solid transparent;
        font-size: 14px;

        vertical-align: top;
        padding-top: 5px;
        padding-bottom: 5px;
        vertical-align: middle;
        word-break: break-word;
        &:first-child {
          padding-left: 35px;
        }
        img {
          display: block;
          width: 20px;
          vertical-align: middle;
          margin-left: -35px;
          float: left;
        }
      }
      @media screen and (max-width: 520px) {
        td.-hideOnMobile {
          display: none;
        }
      }
    }
  }

  /* PRAVA CAST komunikace a chat */
  &Right {
    width: 590px;
    margin-left: 20px;
    flex: 1;
    @media screen and (max-width: $lowerSecond) {
      width: 100%;
      margin-left: 0;
    }

  }

  &Komunikace {
    display: flex;
    min-height: 63px;
    align-content: center;
    justify-content: center;
    a {
      margin-left: auto;
      margin-bottom: auto;
      margin-top: auto;
    }
  }

  .caseChat {
    max-height: 250px;
    overflow-x: hidden;
    @media screen and (max-width: 500px) {
      display: none;
    }
  }

  &File {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white;
    z-index: 20;
    transition: 0.2s opacity;
    text-align: center;
    &.-open {
      opacity: 1;
      visibility: visible;
    }
    &Content {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      //justify-content: center;
      align-items: center;
    }
    &--close {
      position: absolute;
      width: 40px;
      cursor: pointer;
      right: 10px;
      top: 10px;
      padding: 10px;
    }
    &--upload {
      display: none;
      margin-top: 10px;
      &.-ready {
        display: inline-block;
      }
    }
    &Label {
      //background-color: rebeccapurple;
      //margin-top: 40px;
      width: 100%;
      height: calc(100% - 40px);
      height: 100%;
      &.-onDragged {
        background-color: #f1f1f1;
      }
      border: 2px dotted $grey;
      background-color: #f9f9f9;
      span {
        display: inline-block;
        padding-top: 30px;
      }
      * {
        pointer-events:none;
      }
    }
  }

  &Faktu {
    &Header {
      display: flex;
      flex-direction: row;
      a {
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
}

.caseNoCase {
  max-width: 600px;
  display: block;
  width: 100%;
  padding: 0 20px;
}


.moreCasesWrap {
  display: flex;
  justify-content: center;
  margin: 40px 0 35px 0;
}
.moreCases {
  height: 0;
  overflow: hidden;
}

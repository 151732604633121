.profile {
  max-width: 400px;
  margin: 0 auto;
  h5 {
    margin-bottom: 0;
    text-transform: uppercase;
  }
  &Row {
    display: flex;
    align-items: center;
    min-height: 50px;
    span {
      display: inline-block;
      &:first-child {
        min-width: 157px;
      }
    }
  }
  &Content {
    padding-left: 20px;
    padding-right: 20px;
  }
  hr {
    text-align: center;
  }

  &--save {
    margin-top: 10px;
    margin-left: 20px;
  }

  @media screen and (max-width: 400px) {
    &Row {
      flex-direction: column;
      align-items: flex-start;
      span {
        margin-right: auto;
      }
      margin-bottom: 10px;
      input[type=text] {
        width: 100%;
      }
    }
  }
}


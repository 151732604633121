@mixin inputFocus() {
  &:focus {
    outline: none;
    border: 1px solid $orange;
  }
}

@mixin inputBoxShadowWithFocus() {
  box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.1);
  &:focus {
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  }
}


input, textarea, select {
  border: 1px solid $grey;
  color: $basicTextColor;
  border-radius: 3px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #ffffff;
  transition: 0.2s border, 0.7s box-shadow;
  @include inputFocus();
  @include inputBoxShadowWithFocus();
}
input.-error, textarea.-error {
  border: 1px solid #f13858;
}

input[type="text"], input[type="email"], input[type="password"], select {
  height: 40px;
}

textarea {
  padding: 15px;
}

select {
  appearance: none;
  color: transparent;
}

.inputRow {
  @include clearFix();
  margin-bottom: 4px;
  display: block;
  .select {
    display: block;
  }
}
.inputRow.-full {
  & > * {
    width: 100%;
  }
}

input[type="checkbox"] {

}

input[type=checkbox][disabled]{
  border: 1px solid $grey !important;
}
input[type=text][disabled]{
  background-color: #eeeeee;
}

input[type=checkbox][disabled]:checked::before {
  color: $grey !important;
}


input[type="radio"], input[type="checkbox"]
{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  position: relative;
  background-color: $white;
  color: #666;
  top: 10px;
  height: 20px;
  width: 20px;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 7px;
  outline: none;
  border: 1px solid $orange;
  vertical-align: super;
}

input[type="radio"]:checked::before
{
  display: block;
  width: 10px;
  height: 10px;
  content: '';
  background-color: $orange;
  border-radius: 50%;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

input[type="checkbox"] {
  border-radius: 0;
  line-height: 20px;
}
input[type="checkbox"]:checked::before
{
  width:18px;
  height:20px;
  display: block;
  content: '\2715';
  color: $orange;
  //background-color: #eeeeee;
  position: absolute;
  font-size: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
}




.form-radio:hover
{
  background-color: #f7f7f7;
}
.form-radio:checked
{
  background-color: #f1f1f1;
}
label
{
  font-size: 16px;
  color: $basicTextColor;
  line-height: 1.7;
  font-weight: 400;
  cursor: pointer;
}
$headerPaddingLeftRight: 20px;

.topMenu {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-image: url(../img/zahlavi.jpg);
  background-repeat: no-repeat;
  background-size: 100% 28px;
  height: 28px;
  @media screen and (max-width: $mobile) {
    display: none;
  }
  > *:not(.spacer) {
    display: inline-block;
    line-height: 27.5px;
    letter-spacing: 1px;

    text-transform: uppercase;
    color: $white;
    font-size: 13px;
    text-decoration: none;
    min-width: 150px;
    font-weight: bold;
    height: 27px;
    padding-left: $headerPaddingLeftRight;
    padding-right: $headerPaddingLeftRight;
    &:hover {
      color: $headersColor;
    }
    &.-active {
      background: linear-gradient(to right, #ffbc46, #ff9544);
    }
  }
  &Lang {
    text-align: right;
    color: $basicTextColor !important;
    font-weight: normal !important;
    min-width: auto;
  }
}

.menu {
  display: flex;
  align-content: center;

  padding-left: $headerPaddingLeftRight;
  padding-right: $headerPaddingLeftRight;
  //padding-top: 13px;
  height: 60px;
  position: relative;

  @media screen and (max-width: $mobile) {
    border-bottom: 1px solid $orange;
  }



  > * {
    //display: inline-block;
    align-self: flex-end;
    @media screen and (max-width: $mobile) {
      margin: auto;
    }
  }
  &Logo {
    align-self: center;
    display: inline-block;
    img {
      display: block;
      height: 40px;
      width: auto;
    }
    &.-mobile {

    }
  }

  &Burger {
    height: 24px;
    display: none;
    cursor: pointer;
    @media screen and (max-width: $mobile) {
      display: block;
    }

  }

}

@keyframes disop {
  0%   { opacity: 0; visibility: visible; }
  10%   { opacity: 0; visibility: visible; }
  100% { opacity: 1; visibility: visible; }
}


  nav.navMenu {
    display: block;
    z-index: 40;
      > ul {
      margin: 0;
      padding: 0;
      list-style: none;
      > li {
        display: block;
        float: left;
        padding-left: 60px;
        position: relative;
        border-bottom: 1px solid $orange;
        font-size: 18px;
        .modifiedAlert {
          left: 42px;
          top: 4px;
          //width: 10px;
          //height: 10px;
          @media screen and (max-width: $tablet) {
            left: 13px;
          }
          @media screen and (max-width: $mobile) {
            left: 2px;
            top: 12px;
          }
        }
        @media screen and (max-width: $tablet) {
          padding-left: 30px;
        }

        &:first-child {
          padding-left: 0;
        }
        > a {
          display: block;
          text-decoration: none;
          padding-bottom: 5px;
          height: 28px;
          border-bottom: 2px solid #ffffff;
          z-index: 10;
          &.withArrow {
            pointer-events: none;
            svg {
              margin-left: 10px;
              width: 17px;
              path {
                transition: fill 0.2s;
              }
            }
          }
          &:hover, &.-active {
            @include headerMenuHoverState();
          }
        }
        &:hover > ul {
          animation: disop 0.2s forwards;
          animation-delay: 0.2s;
        }

        > ul {
          opacity: 0;
          visibility: hidden;
          display: block;
          position: absolute;
          top: 100%;
          right: 0;
          background: linear-gradient(to bottom, transparent 3px, $white 3px);
          list-style: none;
          margin: 0;
          padding: 0 12px;
          padding-bottom: 8px;
          box-shadow: 0px 5px 5px 0 rgba(0,0,0,0.2);
          min-width: 250px;
          border-radius: 10px;
          &.-extended {
            padding: 0 12px 8px 30px !important;
          }
          li.visibilityStripe {
            background-color: transparent;
          }
          > li {
            border-bottom: 1px solid #dcd8d0;
            font-size: 17px;
            transition: border-bottom 0.2s;
            position: relative;
            .modifiedAlert {
              left: -18px;
              top: 12px;
              //width: 6px;
              //height: 6px;
              @media screen and (max-width: $mobile) {
                left: 0;
                top: 15px;
              }
            }
            &:hover {
              border-bottom: 1px solid $orange;
            }
            &:first-child {
              padding-top: 5px;
            }
            a {
              display: block;
              padding-bottom: 2px;
              text-decoration: none;
              padding-top: 8px;
              transition: padding-left 0.2s;
              &:hover {
                padding-left: 20px;
              }
            }
          }
        }
      }
    }

    &.-header {
      @media screen and (max-width: $mobile) {
        display: none;
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        //bottom: 0;
        background-color: $white;
        //transition: transform .3s ease-in-out;
        //transform: translate(0,calc(-100% - 60px));
        padding: 20px 10px 20px 10px;
        ul > li {
          display: block;
          width: 100%;
          padding-left: 20px;
          padding-top: 10px;
          padding-bottom: 4px;

          &:first-child {
            padding-left: 20px;
          }
          &:last-child {
            border-bottom: none;
          }
          a {
            border-bottom: 0;
            font-size: 16px;
            &:hover {
              border-bottom: 0;
            }
          }
          &:hover > ul {
            animation: none;
            //animation-delay: 0;
          }
          > ul {
            opacity: 1;
            visibility: visible;
            padding: 0;
            border-radius: 0;
            box-shadow: none;
            position: relative;
            padding-bottom: 10px;
            li {
              padding-bottom: 5px;
              padding-top: 5px;
              &:first-child {
                padding-top: 0px;
              }
            }
          }
        }

      }
      &.-open {
        display: block !important;
        //transform: translate(0,0);
      }
    }


  }

$white: #ffffff;
$black: #000000;
$orange: #FFA11F;
$lightOrange: #ffc49f;
$lightGreen: #61AB00;
$grey: #B9AEC3;

$gradientRed: #F51E30;
$gradientYellow: #F8B800;

$footerColor: #f5f4f7;

$basicTextColor: #4A3559;
$headersColor: #66142D;
$linksColor: $headersColor;

$inputBorderColor: #d5d0d9;

$lightPink: #F5F4F7;

.bg-lightPing {
  background-color: $lightPink;
}
.c-brown {
  color: $headersColor;
}

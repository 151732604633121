$modalOverlayTiming: 0.2s;

.modal {
  position: absolute;
  z-index: 999999;
  top: -105%;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 635px;
  display: block;
  //transform: translate(-50%,-50%);
  background-color: $white;
  border-radius: 20px;
  transition: top 0.3s;
  transition-delay: $modalOverlayTiming;
  @media screen and (max-width: $mobile) {
    border-radius: 0;
  }
  h5, p {
    margin: 0 0 10px 0;
  }
  &Content {

    &Wrapper {
      position: relative;
      width: 100%;
      padding: 30px 150px 50px 150px;
      @media screen and (max-width: $mobile) {
        padding: 20px 20px 40px 20px;
      }
    }
    display: flex;
    flex-direction: column;
    max-width: 320px;
    margin: 0 auto;
    text-align: center;
    //overflow-y: scroll;
    //justify-content: center;
    //align-self: center;
    & > * {
      //margin-left: auto;
      //margin-right: auto;
    }
  }
  /* posuny prvku */
  h1 {
    margin-top: 0;
  }
  &Social {
    margin-top: 20px;
    margin-bottom: 15px;
    &Facebook {
      height: 39px;
      width: 39px;
      @media screen and (max-width: $iphone) {
        width: 31px;
      }
    }
  }
  .navMenu {
    margin: 10px auto 15px auto;
    //display: block !important;
  }
  .inputRow {
    &:last-of-type {
      margin-bottom: 15px;
    }
  }
  &Conditions {
    text-align: left;
    padding-left: 15px;
  }
  &Send {
    margin-top: 20px;
  }
  .-login:not([data-modal-switch]) {
    display: none;
  }
  .-register:not([data-modal-switch])  {
    display: none;
  }
  .-password:not([data-modal-switch])  {
    display: none;
  }
  .-passwordChange:not([data-modal-switch])  {
    display: none;
  }
  &--close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .-muiltiuser {
    text-align: left;
    width: 100%;
  }
}

/* nastaveni active stavu pro login a pro register */
.modal {
  &.-login {
    .-login {
      display: block;
    }
    a.-login {
      display: inline-flex;
    }
    .navMenu li a.-login {
      @include headerMenuHoverState();
    }
  }
  &.-register {
    .-register {
      display: block;
    }
    a.-register {
      display: inline-flex;
    }
    .navMenu li a.-register {
      @include headerMenuHoverState();
    }
  }
  &.-password {
    .-password {
      display: block;
    }
    a.-password {
      display: inline-flex;
    }
    .navMenu li a.-password {
      @include headerMenuHoverState();
    }
  }
  &.-passwordChange {
    .-passwordChange {
      display: block;
    }
    a.-passwordChange {
      display: inline-flex;
    }
    .navMenu li a.-passwordChange {
      @include headerMenuHoverState();
    }
  }
}

.modalOverlay {
  //display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -10;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 0;
  //visibility: hidden;
  transition: opacity $modalOverlayTiming ease-in, z-index $modalOverlayTiming;
  &Layer {
    z-index: 1200;
    background: $basicTextColor;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.88;
  }
}

.modalOverlay.-open {
  //visibility: visible;
  z-index: 1100;
  opacity: 1;
  .modal {
    top: 40px;
    @media screen and (max-width: 600px) {
      top: 0;
    }

  }
}

p.input.-error {
  margin: 5px 0;
  color: #f13858;
}
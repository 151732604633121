.dialog {
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 40px;
  max-width: 320px;
  width: 100%;
  display: block;
  z-index: 9999999;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.3);
  text-align: center;
  padding: 20px;
  h5 {
    margin: 0 0 20px 0;
  }
  &Button {
    &:first-of-type {
      margin-right: 10px;
    }
  }

}